;(function() {
"use strict";

/*
 * Use this with form inputs.
 * eg.
 *  <form name="myForm">
 *      <input type="text" name="myInput" ng-required="true">
 *      <error-message input-name="myInput">
 *          <!-- custom entries can go here -->
 *          <p ng-message="min">MO BIGGA!</p>
 *      </error-message>
 *  </form>
 */
angular.module('tmr').directive('errorMessage', function ($timeout) {

    var directive = {
        restrict: 'E',
//        replace: true,    // it all breaks if we replace :?
        templateUrl: 'directive/ErrorMessage.html',
        transclude: true,
        require: "^^form",   // we require this directive to be included
                             // in an enclosing form
        scope: {
            inputName: '@',
        },
        link: link,
    };

    function link(scope, element, attrs, form) {
        function isVisible() {
            return form.showValidity
                || form[scope.inputName].$touched
                || form[scope.inputName].$dirty;
        }


        scope.showError = isVisible;
        $timeout(() => scope.formItem = form[scope.inputName], 0);
    };

    return directive;
});
}());
