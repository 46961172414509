;(function() {
"use strict";

angular
    .module('tmr')
    .directive('trip', trip);

/* @ngInject */
function trip($timeout, Config, User, Options) {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/trip.html',
        link: link,
        scope: {
            trip: '=',
            prev: '=',
            next: '=',
            name: '@',

            trips: '=',
            otherPeople: '=',
            vehicles: '=',

            // Note that we were including tripData as a service and setting
            // scope.tripData = tripData, but the tmr-select directives within
            // were getting linked before this was getting linked, and the
            // tripData hadn't been set yet. Passing tripData in as an attribute
            // solves the problem but feels a bit hacky to me.
            tripData: '=',
        },
    };

    function link(scope, element, attrs) {

        // Just for convenience so we don't need to keep using scope.blah
        // Be careful to only use reference types here.
        var trip    = scope.trip;
        var prev    = scope.prev;
        var next    = scope.next;
        var trips   = scope.trips;

        var form    = scope.tripForm;
        var isFirst = !prev;
        var isLast  = !next;

        // Save the backup copy *after* the next digest cycle,
        // so that angular has filled out the defaults.
        var backup;
        $timeout(function() { backup = angular.copy(trip) }, 0, false);

        // Interface query functions
        function getDescription(direction) {
            var endpoint = trip[direction];

            if (!endpoint.type
                    || endpoint.type.isDefault
                    || !endpoint.type.mode)
                return endpoint.description || direction;

            var mode = endpoint.type.mode;
            var location = _.getPath(endpoint.location, mode, { });
            var hasRoutes = endpoint.type.hasRoutes;

            if (hasRoutes && location.stop) {
                return location.stop;
            }

            if (!hasRoutes && endpoint.description) {
                return endpoint.description;
            }

            if (!endpoint.type.isDefault) {
                return endpoint.type.label;
            }

            return direction;
        }

        function getStatus() {
            if (form.$valid)        { return 'complete' }
            if (form.$error.error)  { return 'invalid'  }
            return                           'incomplete';
        }

        function isModified() {
            return !angular.equals(trip, backup);
        }

        function canCopyCar() {
            if (!prev) {
                return false;
            }

            let isEmpty = (trip.how.whichcar == undefined) || trip.how.whichcar.isDefault;
            if (!isEmpty) {
              return false;
            }

            if (!prev.how.mode.hasDriver) {
                return false;
            }

            if (trip.how.mode.id !== prev.how.mode.id) {
                return false;
            }

            return true;
        }

        // UI event handlers
        function modeChanged() {
            // If the trip is a fixed mode, set the endpoints to the only ones
            // that make sense. eg. train trip -> to/from train stations
            if (trip.how.mode && trip.how.mode.endpoint) {
                trip.departure.type = trip.how.mode.endpoint;
                trip.arrival.type = trip.how.mode.endpoint;

                // If we already have a departure route set (which might've been
                // copied from the previous trip), set the arrival route to the
                // same thing.
                routeChanged('departure');
            }


            // Get mode and vehicle from last trip
            // if same mode is selected in the next trip then
            // pre-populate the same vehicle as selected in prev trip

            if (canCopyCar()) {
                trip.how.whichcar = prev.how.whichcar;
            }

        }

        function destinationChanged() {
            // based on the arrival type, auto-populate the reason
            if (_.getPath(trip, "arrival.type.reason") && _.getPath(trip, "why.reason.isDefault")){
                let reason = _.findWhere(scope.tripData.reasons, { id: trip.arrival.type.reason });
                trip.why.reason = angular.copy(reason);
            }
        }

        function routeChanged(direction) {
            var opposite = otherDirection(direction);
            var mode = trip[direction].type.mode;

            // Make sure location exists for both. This might happen if the
            // trip has been saved and reloaded before these get populated.
            // Our stupid DynamoDB library will delete empty objects.
            // See https://gh.sdintra.net/Client-TMR/survey/issues/403
            _.defaults(trip.departure, { location: { } });
            _.defaults(trip.arrival,   { location: { } });

            // Make sure location[mode] exists for both
            _.defaults(trip.departure.location, { [mode]: { } });
            _.defaults(trip.arrival.location,   { [mode]: { } });

            if (!trip[opposite].location[mode].route) {
                trip[opposite].location[mode].route
                    = trip[direction].location[mode].route;
            }
        }

        function locationChanged(direction) {
            var location = trip[direction];
            var type = trip[direction].type;

            if (!type) {
              return;
            }

            if (type.isDefault) {
                return;
            }

            if (type.hasRoutes) {
                // TODO: Pre-populate routes & stops here.
                return;
            }

            if (!location.description) {
                var prev = findPlace(
                    loc => loc.description && (loc.type.id === type.id)
                );
                if (prev) {
                    location.description = prev.description;
                    if (prev.other) {
                        location.other = prev.other;
                    }
                    if (_.has(prev, 'geocodeData')) {
                        location.geocodeData = prev.geocodeData;
                    }
                }
                else if (type.id === 'home') {
                    // This data got looked up by the backend.
                    let address = User.getData('const').address;
                    if (address) {
                        location.description = address.formatted_address;
                        location.geocodeData = address;
                    }
                }
            }
        }

        function doDelete(event) {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            emit('delete');
        }

        function doSave() {
            angular.copy(trip, backup);
            // Delay this so you can't see the flash of the
            // errors as the accordion closes.
            $timeout(() => form.showValidity = true, 1000);

            emit('save');
        }

        function gotoPage(destination) {
            emit('goto', destination);
        }

        function doUndo() {
            angular.copy(backup, trip);
        }

        // Validation functions
        function validateArrivalTime() {
            if (trip.arrival.time <= trip.departure.time) {
                return false;
            }
            return true;
        }

        function validateDepartureTime() {
            return isFirst || (prev.arrival.time <= trip.departure.time);
        }

        // Utility
        function id(uniq) {
            return scope.$id + uniq;
        }

        function otherDirection(direction) {
            if (direction == 'departure') { return 'arrival'   }
            else                          { return 'departure' }
        }

        function emit(eventName, ...args) {
            scope.$emit('trip-' + eventName, trip, ...args);
        }

        function watch(item, handler, ...handlerArgs) {
            scope.$watch(item, (newValue, oldValue) => {
                if (newValue != oldValue) {
                    handler(...handlerArgs, newValue, oldValue);
                }
            });
        }

        function triggerValidationOn(watchItem, ...inputNames) {
            watch(watchItem, function() {
                _.each(inputNames, i => {
                  if (form[i]) { form[i].$validate(); }
                });
            });
        }

        function rangeForSelect(min, max, defaults) {
            var selectOptions = [];
            _.each(defaults, (val) => selectOptions.push(val));

            for (var i = min; i <= max; i++) {
                var label = i < 10 ? '0' + i : i;
                selectOptions.push({
                    label: label,
                    id: i
                });
            }
            return selectOptions;
        }

        function findPlace(predicate) {
            var match = _.chain(trips)
                            .map(t => [ t.departure, t.arrival ])
                            .flatten()
                            .filter(predicate)
                            .last()
                         .value();
            return match;
        }

        var minutesDefaults = [
                {
                    label: 'Minutes',
                    id: '',
                    isDefault: true,
                },
                {
                    label: 'Stayed with vehicle',
                    id: 'stayed-with-vehicle',
                },
                {
                    label: '< 1',
                    id: 0,
                }
        ];

        function peopleOptions() {
            function asOption(person) {
                return Options.makeOption(person.id, person.name);
            }

            if (_.isEmpty(scope.otherPeople))
                return; // no other people - hide it

            var options = _.map(scope.otherPeople, asOption);

            return options;
        }

        // I went through all sorts of hoo-hah trying to force validations on
        // other trips. The simple trick is to go the other way, rather than
        // watching our values and trying to force validations on other trips,
        // watch the values of the other trips (which are easy to get to), and
        // force validations on this trip. Duh!
        triggerValidationOn('prev.arrival.time',   'fromTime');
        triggerValidationOn('trip.departure.time', 'toTime');

        watch('trip.departure.type', locationChanged, 'departure');
        watch('trip.arrival.type',   locationChanged, 'arrival');

        // If trip existed and has been navigated away from, show errors.
        form.showValidity = trip.showValidity;

        // Here we inject the closure locals into the scope.
        _.extend(scope, {
            doDelete:               doDelete,
            doSave:                 doSave,
            doUndo:                 doUndo,
            getDescription:         getDescription,
            getStatus:              getStatus,
            gotoPage:               gotoPage,
            id:                     id,
            isModified:             isModified,
            modeChanged:            modeChanged,
            destinationChanged:     destinationChanged,
            routeChanged:           routeChanged,
            uniqueId:               scope.$id,
            validateArrivalTime:    validateArrivalTime,
            validateDepartureTime:  validateDepartureTime,
            peopleOptions:          peopleOptions(),
            minutesOptions:         rangeForSelect(1, 30, minutesDefaults),
            //occupantOptions:        _.range(1, 1 + 12),
            occupantOptions:        _.range(1, 1 + 11), // 11 for now
        });

        // And the Config.
        _.extend(scope, Config);
    }

    return directive;
}
}());
