;(function() {
"use strict";

const tmrAssistanceComponent = {
    templateUrl: 'components/tmr-assistance.html',
    controller: tmrAssistanceController,
    controllerAs: 'vm',
};

/* @ngInject */
function tmrAssistanceController(Tenant) {
    let vm = this;

    angular.extend(vm, {
        support: Tenant.support,
    });
}

angular
    .module('tmr')
    .component('tmrAssistance', tmrAssistanceComponent);
}());
