;(function() {
"use strict";

angular
    .module('tmr')
    .factory('ModalFactory', ModalFactory);

/* @ngInject */
function ModalFactory($modal, Tenant) {
    // The controller is so small there's no point in breaking it out into a
    // separate file.
    function controller(scope, modalInstance, data) {
        scope.ok = function(result = true) {
            modalInstance.close(result);
        }
        scope.cancel = function() {
            modalInstance.dismiss('cancel');
        };

        scope.data = data;
        scope.tenant = Tenant;
    }

    function open(template, data = { }) {

        if (template.search('/') == -1) {
            // If the template path contains slashes, leave it be, otherwise
            // prepend the regular template path.
            template = 'partials/modals/' + template;
        }

        let params = {
            templateUrl: template,
            controller: [ '$scope', '$modalInstance', 'data', controller ],
            resolve: {
                data: function() { return data },
            },
        };

        return $modal.open(params);
    }

    return {
        open: open,
    };
}
}());
