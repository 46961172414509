;(function() {
"use strict";

angular
    .module('tmr')
    .factory('PersonAge', PersonAge);

/* @ngInject */
function PersonAge( User) {
    let constant = User.getData('const');
    const tripDate = new Date(constant.date);
    const tripYear = tripDate.getFullYear();

    function getAgeRange(birthYear) {
        if (_.isUndefined(birthYear)) {
            return { unknown: true }; // we don't know the age
        }

        let maxYear = tripYear - birthYear;

        let minYear = maxYear - 1;

        let range = {
            max: maxYear,
            min: minYear
        };

        return range;
    }

    return {
        getAgeRange: getAgeRange,
    };
};
}());
