;(function() {
"use strict";

/*
    General purpose confirmation dialog.

    Call it like this:

        var message = "Are you sure you want to delete this trip?";
        // The options are all optional - see defaults below.
        var options = {
            yesMessage: "Delete it",
            noMessage:  "No, go back!",
            yesIcon:    "fa-trash-o",
            noIcon:     "",             // empty = no icon
        };
        confirmationModal.confirm(message, options)
            .then(function() { whenYes() },
                  function() { whenNo()  });
*/

angular
    .module('tmr')
    .factory('ConfirmationModal', ConfirmationModal);

/* @ngInject */
function ConfirmationModal($modal, $sce) {

    function confirm(message, data = {}) {

        var defaults = {
            yesMessage: "OK",
            noMessage:  "Cancel",

            yesIcon:    "fa-thumbs-o-up",
            noIcon:     "fa-thumbs-o-down",
        };

        // This is such a trivial controller it makes sense to make it inline.
        // This way we can use a closure rather than the resolve mechanism.
        // *Seems* to work ok.
        var controller = ['$scope', '$modalInstance',
            function (scope, modalInstance) {
                _.extend(scope, defaults);
                _.extend(scope, data);
                scope.message = message;

                if (scope.information) {
                    scope.information = $sce.trustAsHtml(scope.information);
                }

                scope.yesButton = function() {
                    modalInstance.close(true);
                };

                scope.noButton = function() {
                    modalInstance.dismiss('cancel');
                };
            }
        ];

        var params = {
            templateUrl: "partials/modals/confirmation-modal.html",
            controller: controller,
        };

        return $modal.open(params).result;
    };

    return {
        confirm: confirm,
    };
}
}());
