;(function() {
"use strict";

angular
    .module('tmr')
    .factory('SchemaVersion', SchemaVersion);

/* @ngInject */
function SchemaVersion($log, $window, AlertModal) {

    // Make sure this matches $survey_version in
    //  backend/lib/TMR/App.pm
    const currentSchemaVersion = 4;

    if (Number.parseInt === undefined) {
        Number.parseInt = window.parseInt;
    }

    function getSurveyVersion(survey) {
        const version = _.getPath(survey, 'const.version');
        return _.isUndefined(version) ? 1 : Number.parseInt(version);
    }

    function setSurveyVersion(survey, version) {
        if (!survey.const) {
            survey.const = { };
        }

        survey.const.version = version;
    }

    function verifySurveyVersion(surveyVersion) {
        if (surveyVersion <= currentSchemaVersion) {
            return;
        }

        $log.debug(`Survey data version: ${surveyVersion}`);
        $log.debug(`Survey code version: ${currentSchemaVersion}`);

        const opts = { closeButtonText: "Update" };
        const message = `
An updated version of the South Queensland Travel Survey is available.
Please refresh your browser window, or click the 'Update' button.
`;
        AlertModal(message, opts).then(() => { $window.location.reload() });
    }

    function upgrade(survey) {
        const upgraderTable = {
            1: upgradeV1V2,
            2: upgradeV2V3,
            3: upgradeV3V4,
        };

        let version = getSurveyVersion(survey);

        verifySurveyVersion(version);
        while (version < currentSchemaVersion) {
            const upgrader = upgraderTable[version];

            $log.debug(`Upgrading survey v${version} to v${version + 1}`);
            upgrader(survey);
            version++;

            setSurveyVersion(survey, version);
        }

        return;
    }

    return {
        upgrade,
    };
}

// Schema upgrade functions to follow. There modify the survey data in-place.
// There is no need to check the old version number, nor set the new one, the
// calling code does this.
//
// Please name the function `upgradeV${from-version}V${to-version}`.
//
// Don't forget to add it to the upgraderTable above.

function upgradeV1V2(survey) {
    let people = _.getPath(survey, 'adminPage.people');

    // The V1 existing travelled_with array contains:
    //    { id: person.name, text: person.name }
    //
    // We convert this to use person.id for the id.

    function normalise(name) {
        return name.toLowerCase();
    }

    // Create a lookup table of name -> people
    const personByName = _.chain(people)
                          .filter('name') // only if they have a name
                          .indexBy(person => normalise(person.name))
                          .value();

    function remapTravelledWith(trip) {
        let travelled_with = _.each(trip.how.travelled_with, option => {
            if (option.id == option.text) {

                let name = normalise(option.text);
                let person = personByName[name];
                if (_.isUndefined(person)) {
                    //console.log(`Can't find matching person for ${option.name}`);
                    return; // skip this one
                }
                option.id = person.id;
            }
            return option;
        });

        // Any unfound people will now be undefined. Use _.compact to
        // remove them.
        trip.how.travelled_with = _.compact(travelled_with);
    }

    // Remap travelled_with for all trips for all people.
    _.each(people, person => {
        _.each(person.tripList, remapTravelledWith)
    });
}

function upgradeV2V3(survey) {
    let people = _.getPath(survey, 'adminPage.people');

    // In version 2 surveys, underFive was declared, so we know for sure if
    // the person was under five or not. We can carry that into the
    // confirmsUnderFive for version 3.
    _.each(people, person => {
        person.confirmsUnderFive = {
            // We only need the id field here. The rest will be filled out by
            // angular.
            id: person.underFive ? "yes" : "no",
        };
    });
}

function upgradeV3V4(survey) {
    let people = _.getPath(survey, 'adminPage.people');

    // Consider everyone before v4 to have visited the questions page, even
    // if they didn't. Otherwise we get previously-completed surveys showing
    // as incomplete.
    _.each(people, person => {
        _.defaults(person, { questions: { } });
        person.questions.completionStatus = 'complete';
    });
}
}());
