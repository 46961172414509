;(function() {
"use strict";

angular.module('tmr').directive('timePicker', ['Timestamp',
function (ts) {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/timepicker.html',
        link: link,
        scope: {
            timestamp: '=ngModel',
        },
    };

    function link(scope, element, attrs, ngModelCtrl) {
        // It seems our offline database fixup scripts have changed some ints
        // into strings. Usually this works out okay, but in this case:
        // 100 > 90, but "100" < "90".
        if (_.isString(scope.timestamp)) {
            scope.timestamp = parseInt(scope.timestamp);
        }
        getTimestamp();

        scope.range = function(min, max) {
            return _.range(min, max + 1);
        };

        function pad(str, width=8, fillchar='0') {
            var out = str.toString();
            while (out.length < width) {
                out = fillchar + out;
            }
            return out;
        };

        function hourId(h)      { return h % 24 };
        function hourLabel(h)   {
            var oclock = ((h - 1) % 12) + 1;
            return pad(oclock, 2, '0');
        }
        function hourGroup(h)   {
            if (h < 12) { return "Travel Day AM" }
            if (h < 24) { return "Travel Day PM" }
            return "Next Morning AM";
        }

        scope.hours = _.map(_.range(4, 28), function(h) {
            return {
                id: hourId(h),
                label: hourLabel(h),
                group: hourGroup(h),
            }
        });
        scope.minutes = _.map(_.range(0, 60), function(m) {
            return {
                id: m,
                label: pad(m, 2, '0') } }
        );

        scope.setTime = setTimestamp;
        scope.$watch('timestamp', getTimestamp);

        function getTimestamp() {
            scope.hour   = { id: ts.hourRaw(scope.timestamp).toString() },
            scope.minute = { id: ts.minute(scope.timestamp).toString()  },
            scope.ampm   = ts.ampm(scope.timestamp);
        }

        function setTimestamp() {
            scope.timestamp = ts.make(scope.hour.id, scope.minute.id);
        }
    }

    return directive;
}]);
}());
