;(function() {
"use strict";

angular
    .module('tmr')
    .controller('TripsController', TripsController);

/* @ngInject */
function TripsController($scope, $stateParams, Animation, User, TripData, TripUtils, Timestamp, ConfirmationModal, InvalidDataModal, SaveModal, Navigation, Tenant, Validator, $q) {
    $scope.tripData = TripData;
    $scope.tenant = Tenant;

    var validator = new Validator($scope, 'allTripsForm');

    var personId = $stateParams.personId;
    var admin    = User.getData('adminPage');
    let constant = User.getData('const');

    $scope.$on('back-to-admin-app', (event) => {
        beforeNav();

        let hasInvalidData = validator.hasErrors;

        InvalidDataModal.show(hasInvalidData).then(ok => {
            Navigation.backToAdminApp(User.isLoggedIn());
        });
    });

    let person = _.findWhere(admin.people, { id: personId });
    if (_.isUndefined(person) || person.underFive) {
        Navigation.redirectToHome();
        return;
    }

    _.defaults(person, {
        trips: { },
    });

    var vehiclesPage = User.getData('vehiclesPage');

    function vehicleName(v) {
        let year = v.vehicleYear.label;
        let make = v.vehicleMake.hasOther ? v.vehicleMakeOther
                                          : v.vehicleMake.label;
        let model = v.vehicleModel.hasOther ? v.vehicleModelOther
                                            : v.vehicleModel.label;

        return _.chain([ year, make, model ])
                 .reject(_.isUndefined)
                 .join(' ')
                 .value();
    }

    function makeVehicleOption(vehicle) {
        try {
            return { id: vehicle.vehicleId, label: vehicleName(vehicle) };
        }
        catch (e) {
            return undefined;
        }
    }

    $scope.vehicles = _.compact(_.map(vehiclesPage.vehicles, makeVehicleOption));
    $scope.vehicles.unshift({ label: 'Select one', id: '', isDefault: true });
    $scope.vehicles.push({
        id: 'other',
        label: 'Another vehicle not owned by our household'});

    // If the person didn't travel, don't need this page.
    if (person.travel.noTrips) {
        Navigation.next();
        return;
    }

    if (!person.tripList) {
        // First time this page has been loaded.
        // Copy all top-level trips this person was mentioned in. Top-level trips
        // are ones that were entered directly, not copied from someone else.
        let tripList = [];

        _.each(admin.people, otherPerson => {
            if (otherPerson.id === person.id) {
                return; // skip self
            }

            if (!otherPerson.tripList) {
                return; // no trips
            }

            let uncopiedTrips =
                _.filter(otherPerson.tripList, t => !t.copiedFrom); // ignore already copied

            _.each(uncopiedTrips, function(trip) {
                let travelledWithIds = _.pluck(trip.how.travelled_with, 'id');
                if (_.contains(travelledWithIds, personId)) {
                    let copiedTrip        = angular.copy(trip);

                    // TODO: What to do here? Let this ride for now.
                    copiedTrip.copiedFrom = otherPerson.name;

                    // Don't need full trip data, trim out the extra bits here.
                    let copiedTripTrimmed = _.omit(copiedTrip, 'how', 'why');

                    // We only need these bits.
                    copiedTripTrimmed.how = {
                        mode:  copiedTrip.how.mode,
                        other: copiedTrip.how.other,
                        occupants: copiedTrip.how.occupants,
                    };

                    // Determine if the user was driver or passenger of the vehicle.
                    if (copiedTrip.how.mode.hasDriver) {
                        let driverOrPassenger           = copiedTrip.how.isDriver == 'driver' ? 'passenger' : 'driver';
                        copiedTripTrimmed.how.isDriver = driverOrPassenger;
                        copiedTripTrimmed.how.wherepark = copiedTrip.how.wherepark;
                        copiedTripTrimmed.how.whichcar  = copiedTrip.how.whichcar;

                    }

                    // Filter current person out of travelled_with list and reverse travelled_with
                    let copiedTravelledWith = _.filter(copiedTrip.how.travelled_with, person => person.id != personId);
                    copiedTravelledWith.push({ id: otherPerson.id, text: otherPerson.name });
                    copiedTripTrimmed.how.travelled_with = copiedTravelledWith;

                    tripList.push(copiedTripTrimmed);
                }
            });
        });

        person.tripList = tripList;
    }

    // These are the other people that you may have shared the trip with.
    let otherPeople = _.without(admin.people, person);

    _.defaults(person, {
        questions: { },
    });

    // Export data items into the scope
    _.extend($scope, {
        name:           person.name,
        otherPeople:    otherPeople,
        travelDate:     constant.date,
        tripList:       person.tripList,
        questions:      person.questions,
    });


    var tripList = person.tripList;
    function onTripEvent(eventName, handler) {
        $scope.$on(eventName, (e, ...args) => {
            e.stopPropagation();
            handler(e, ...args);
        });
    }

    onTripEvent('trip-delete', (e, trip) => {
        var message = "Are you sure you want to delete this trip?";
        var options = {
            yesMessage: "Yes, delete it",
            noMessage:  "No, keep it",
            yesIcon:    "fa-trash-o",
            noIcon:     "",
        };
        ConfirmationModal.confirm(message, options)
            .then(function() { _.arrayRemove(tripList, trip) });
    });

    onTripEvent('trip-save', (e, trip) => {
        _.each(tripList, trip => {
            console.log(`Vehicle occupants: ${trip.how.occupants}`);
        });
        reorderTrips(trip);
        trip.showValidity = true;
        SaveModal.save();
    });

    onTripEvent('trip-insert-after', (e, trip) => {
        var index = tripList.indexOf(trip);
        insertTripBetween(trip);
    });

    onTripEvent('trip-insert-between', (e, trip) => {
        var index = tripList.indexOf(trip);
        insertTripBetween(trip, tripList[index+1]);
    });

    onTripEvent('trip-goto', (e, trip, destination) => {
        Navigation.navigate(destination);
    });

    $scope.newtrip = {
        reset: function() {
            if (tripList.length > 0) {
                // Make the new leave time the leave time or the arrival time
                // of the final trip, whichever one is later.
                var lasttrip = tripList[tripList.length - 1];
                if (lasttrip.departure.time < lasttrip.arrival.time) {
                    this.time = lasttrip.arrival.time;
                }
                else {
                    this.time = lasttrip.departure.time;
                }
            }
            else {
                // default case
                this.time = Timestamp.make();
            }
        },
    };
    $scope.newtrip.reset();
    $scope.showMissingTrips = false;

    var showInsertionPoint = true;
    $scope.insertionIndex = function() {
        if (!showInsertionPoint) {
            return -1;  // none will match - none will be displayed
        }

        return insertionIndex($scope.newtrip.time);
    }

    function insertionIndex(time) {
        // We want to keep the trips list sorted on departure time.
        // In the case of a tie, the new trip loses.
        var index = _.findIndex(tripList, trip => time < trip.departure.time);
        if (index < 0) {
            index = tripList.length; // not found means add to end
        }

        return index;
     }

     function updateIndex(trip) {
        var index = tripList.indexOf(trip);
        if (index < 0) {
            return index;
        }

        function orderOkay(i, j) {
            if (i < 0 || j >= tripList.length ) {
                return true; // trip is at end of list,
                                // so nothing to compare
            }
            return tripList[i].departure.time <= tripList[j].departure.time;
        }

        // First check if the current trip position is valid. This avoids
        // the problem where an unchanged trip moves around the list.
        if (orderOkay(index - 1, index) &&
            orderOkay(index, index + 1)) {
            return index;
        }

        return insertionIndex(trip.departure.time);
    }

    function insertTrip(trip, index = insertionIndex(trip.departure.time)) {
        _.arrayInsert(tripList, trip, index);
    }

    function insertTripBetween(prev, next) {
        // next may be undefined, in which case we only want to fill out the
        // departure details
        let trip = createTrip(prev);

        trip.departure.time = prev.arrival.time;

        if (next) {
            trip.arrival.time = next.departure.time;

            trip.arrival.description = next.departure.description;
            trip.arrival.location    = angular.copy(next.departure.location);
            trip.arrival.type        = angular.copy(next.departure.type);
        }
        else {
            trip.arrival.time = trip.departure.time;
        }

        let insertionIndex = 1 + tripList.indexOf(prev);
        addNewTrip(trip, { insertionIndex: insertionIndex });
    }

    function createTrip(prevTrip) {
        var trip = {
            isNew: true,
            departure: {
                time: $scope.newtrip.time,
                location: { },
            },
            arrival: {
                time: $scope.newtrip.time,
                location: { },
            },
            how: {

            },
            why: {

            },
        };

        var dest = trip.departure;

        if (prevTrip) {
            var src = prevTrip.arrival;

            dest.location    = angular.copy(src.location);
            dest.type        = angular.copy(src.type);

            if (src.type.hasOther) {
              dest.other = angular.copy(src.other);
            }

            dest.description = src.description;
            dest.geocodeData = src.geocodeData;
        }
        else {

            if (person.travel.startingPlace.isHome) {
                let type = _.findWhere($scope.tripData.endpoints, {id: 'home'});
                trip.departure.type = angular.copy(type);

                let address = findHomeAddress(tripList);

                if (address) {
                    dest.description = address.description;
                    dest.geocodeData = address.geocodeData;
                }
            }
        }

        return trip;
    }

    function findHomeAddress(tripList) {

        for (var i = 0; i < tripList.length; i++){

            var trip = tripList[i];

            if (trip.departure.type.id === "home") {
                return trip.departure;
            }
            if (trip.arrival.type.id === "home") {
                return trip.arrival;
            }
        }

        let address = User.getData('const').address;

        if (address) {

            return {
                description: address.formatted_address,
                geocodeData: address,
            };
        }

        address = User.getData('const').person.address;

        if (address) {

            return {
                description: address,
                geocodeData: undefined,
            };
        }

        return undefined;
    }

    $scope.addTrip = function(preventScroll = false) {
        var index = $scope.insertionIndex();
        var prevTrip = tripList[index - 1];
        var trip = createTrip(prevTrip);

        if (preventScroll) {
            addNewTrip(trip);
        }
        else {
            addNewTrip(trip, { scrollElement: `trip-insertion-point-${index}` });
        }
    };

    function addNewTrip(trip, options = { }) {
        var openTrip = _.find(tripList, t => t.isOpen);

        // This is the insert trip animation sequence.
        if (openTrip) {
            // Close this early in order to not throw out the animation
            // sequence.
            openTrip.isOpen = false;
        }

        var preventScroll = !options.scrollElement;
        var showMissingTrips = $scope.showMissingTrips;

                                                // scroll to the insertion point
        Animation.scrollToId(options.scrollElement || 'not-an-element', 1000)
        .then(() => {
            showInsertionPoint = false;     // hide the insertion point
            $scope.showMissingTrips = false;  // and the missing trips
            insertTrip(trip, options.insertionIndex);
        })
        .then(Animation.wait(1000))
        .then(() => {
            // Now enable trip change watching so that the trip will scroll
            // into view on open. Nice!
            let setupWatcher = Animation.watchAccordionRowChanges($scope, preventScroll);
            setupWatcher(trip, tripList.indexOf(trip));

            trip.isOpen = true;  // expand the trip
        })
        .then(Animation.wait(1000))
        .then(() => {
            $scope.newtrip.reset();         // update the newtrip
            showInsertionPoint = true;    // show the insertion point
                                            // and the missing trips
            $scope.showMissingTrips = showMissingTrips;
        });
    }

    function reorderTrips(trip) {
        var oldIndex = tripList.indexOf(trip);
        var newIndex = updateIndex(trip);

        if (oldIndex == newIndex) {
            // This trip has not moved. Close the trip and scroll the title
            // into view.
            trip.isOpen = false;
            $scope.newtrip.reset();
            Animation.scrollToAccordionIndex(newIndex);
        }
        else {
            // This trip has moved to a new spot.
            trip.isOpen = false;
            showInsertionPoint = false;   // hide the insertion point
            Animation.scrollToAccordionIndex(Math.min(oldIndex, newIndex), 100)
            .then(() => { _.arrayRemove(tripList, trip) })
            .then(Animation.wait(700))
            .then(() => { insertTrip(trip) })
            .then(Animation.wait(700))
            .then(() => {
                $scope.newtrip.reset();         // update the newtrip
                showInsertionPoint = true;    // show the insertion point
            });
        }
    }


    $scope.getStatus = function() {
        var form = $scope.allTripsForm;

        if      (form.$valid)        { return 'complete'   }
        else if (form.$error.error)  { return 'invalid'    }
        else                         { return 'incomplete' }
    }

    $scope.submitMessage = function() {
        switch ($scope.getStatus()) {
            case 'invalid':
                return "Some of your trips have errors. Check you have corrected all invalid questions before you submit";

            case 'incomplete':
                return "Some of your trips are incomplete. Check you have answered all questions before you submit";
            case 'complete':
                if ($scope.showMissingTrips && anyMissingTrips()) {
                    return "You have completed your trips, but it looks like you might have missed some. Please take the time to review your answers before you submit";
                }
                return "You have completed your trips. Please take the time to review your answers before you submit";

            default:
                return "";
        }
    }

    $scope.canSubmit = function() {
        return $scope.getStatus() === 'complete';
    }

    function submit() {
        checkTrips().then(
            ok => {
                beforeNav();
                Navigation.next();
            }
        );
    }

    function home() {
        checkTrips().then(
            ok => {
                beforeNav();
                Navigation.home();
            }
        );
    }

    function checkTrips() {
        let tripErrors = TripUtils.checkTripList(tripList);

        // tripErrors is a list of error objects. We want the unique
        // explanation messages, with the most severe first.
        let messages = _.chain(tripErrors)
                        .sortBy('severity')
                        .map('explanation')
                        .unique(true)
                        .reverse()
                        .value();

        if (!hasEndedUpWhereStarted()) {
             messages.push('Your Travel Diary shows that you ended the day at a different place to where you started.');
        }

        if (messages.length == 0) {
            return $q.resolve(true);
        }

        let message = "Are you sure?";
        let options = {
            yesMessage:  "I know, proceed anyway",
            noMessage:   "Go back and review",
            yesIcon:     "",
            noIcon:      "",
            information: _.map(messages, msg => '- ' + msg)
                          .join("<br><br>\n"),
        };

        options.information += "<br><br><br>If you've missed a trip, please go back to the Travel Diary and review your trips. Otherwise you can ignore this warning and submit your Travel Diary by clicking ‘Next’ again.";

        return ConfirmationModal.confirm(message, options).then(
            yes => {

            },
            no => {
                $scope.showMissingTrips = true;
                scrollToFirstError();
                return $q.reject(false);
            }
        );

    }

    function scrollToFirstError() {
        let firstErrorIndex = findFirstErrorIndex();
        if (firstErrorIndex !== undefined) {
            let openTrip = _.find(tripList, t => t.isOpen);

            if (openTrip) {
                openTrip.isOpen = false;
            }
            Animation.afterNextDigest(() => {
                Animation.scrollToAccordionIndex(firstErrorIndex);
            });
        }
    }

    function beforeNav() {
        person.trips.completionStatus = validator.status;
        _.each(person.tripList, trip => trip.showValidity = true);
    }

    _.extend($scope, {
        submit : submit,
        home   : home,
        prev   : Navigation.wrapPrev(beforeNav),
    });

    function hasEndedUpWhereStarted() {
        // We need at least one trip, but don't be fooled into thinking we need
        // two. We need two places, and one trip goes from one place to another.
        // We need to do this check if there's only one trip.
        if (tripList.length === 0) {
            return true;
        }

        var startedTripAt = tripList[0].departure;
        var endedTripAt = tripList[tripList.length - 1].arrival;

        return TripUtils.sameLocation(startedTripAt, endedTripAt);
    }

    function findFirstErrorIndex() {
        for (let i = 0; i < tripList.length - 1; i++) {
            let prev = tripList[i];
            let next = tripList[i + 1];
            let error = TripUtils.checkTripPair(prev, next);
            if (!error.isValid) {
                return i;
            }
        }
        return -1;
    }

    function anyMissingTrips() {
        return findFirstErrorIndex() >= 0;
    }

    // Setup the watches
    _.each(tripList, Animation.watchAccordionRowChanges($scope));

    // Add an initial trip if we don't have one.
    if (tripList.length === 0) {
        $scope.addTrip(true);
    }
}
}());
