;(function() {
"use strict";

angular
    .module('tmr')
    .factory('Backend', Backend);

/* @ngInject */
function Backend($http, $log, $state) {
    let service           = {};
    let authHeader        = 'Authorization';
    let hasSessionStorage = checkForSessionStorage();
    let adminUserIdHeader = 'x-admin-user-id';
    let adminUserId       = undefined;
    let permissionsHeader = 'x-permissions';
    let permissionString  = undefined;
    let permissions       = { };

    // Export the API functions to the service object.
    _.extend(service, {
        hasAuth:            hasAuth,
        hasPermission:      hasPermission,
        isAdmin:            isAdmin,
        register:           register,
        login:              login,
        adminLogin:         adminLogin,
        logout:             logout,
        load:               load,
        save:               save,
        status:             status,
        deleteSurvey:       deleteSurvey,
    });

    return service;

    function isAdmin() {
        return adminUserId;
    }

    function checkForSessionStorage() {
        var test = 'test';
        try {
            sessionStorage.setItem(test, '1');
            sessionStorage.removeItem(test);
            return true;
        }
        catch (error) {
            return false;
        }
    }

    // Note: dns failure or connection refused gives status == -1
    // External API functions
    function register(email) {
        var request = gzPost(url('register'), {
            email: email,
        });
        return request;
    }

    function login(token, postcode, travelDate) {
        var request = gzPost(url('login'), {
            token: token,
            postcode: postcode,
            date: travelDate,
        });
        return request.then(
            response => {
                setAuth(response);  // store the jwt token
                clearSessionItem(adminUserIdHeader);
                return response.data;
            }
        );
    }

    function adminLogin(jwt) {
        var request = gzPost(url('login'), { jwt: jwt });
        return request.then(
            response => {
                adminUserId = response.headers(adminUserIdHeader);
                setSessionItem(adminUserIdHeader, adminUserId);

                permissionString = response.headers(permissionsHeader) || '';
                permissions = parsePermissions(permissionString);
                setSessionItem(permissionsHeader, permissionString);

                setAuth(response);  // store the jwt token
                return response.data;
            }
        );
    }

    function logout() {
        clearAuth();
        clearSessionItem(adminUserIdHeader);
    }

    function load() {
        var request = $http.get(url('data'));
        return request.then(
            response => {
                setAuth(response);  // store the jwt token
                return response.data;
            }
        );
    }

    function save(surveyData, fromState, toState, personId) {
        let data = {
            survey_data: surveyData,
            from_page:  fromState,
        };

        if (toState) {
            data.to_page = toState;
        }
        if (personId) {
            data.person_id = personId;
        }

        let request = gzPut(url('data'), data);
        return request.then(
            response => {
                setAuth(response);  // refresh the jwt token
                return response;
            }
        );
    }

    function deleteSurvey(reason, data) {
        var request = gzPut(url('delete/' + reason), data);
        return request.then(
            response => {
                setAuth(response);  // refresh the jwt token
                return response;
            }
        );
    }

    // Internal
    function url(path) {
        return `/api/${path}`;
    }

    function setAuth(response) {
        var auth = response.headers(authHeader);
        $http.defaults.headers.common[authHeader] = auth;
        setSessionItem(authHeader, auth);
    }

    function hasAuth() {
        // Already have auth set in $http headers
        if ($http.defaults.headers.common[authHeader]) {
            return true;
        }

        if (_.isUndefined(adminUserId)) {
            adminUserId = getSessionItem(adminUserIdHeader);
        }

        if (_.isUndefined(permissionString)) {
            permissionString = getSessionItem(permissionsHeader) || '';
            permissions = parsePermissions(permissionString);
        }

        // Check if we have a saved auth in the session
        var sessionAuth = getSessionItem(authHeader);
        if (sessionAuth) {
            // Update the $http auth
            $http.defaults.headers.common[authHeader] = sessionAuth;
            return true;
        }
        return false;
    }

    function hasPermission(key) {
        return permissions[key];
    }

    // The permission string is of the form "key1, key2, key3"
    // (This is how angular $http presents multi-valued headers :/)
    // Return an object with: { key1: true, key2: true, key3: true }
    function parsePermissions(str) {
        if (str.length === 0) {
            return { };
        }
        let hasPermission = { };
        _.each(str.split(', '), key => { hasPermission[key] = true });
        return hasPermission;
    }

    function clearAuth() {
        clearSessionItem(authHeader);
        delete $http.defaults.headers.common[authHeader];
    }

    function setSessionItem(key, value) {
        if (hasSessionStorage) {
            sessionStorage.setItem(key, value);
        }
    }

    function getSessionItem(key) {
        if (hasSessionStorage) {
            return sessionStorage.getItem(key);
        }
        return undefined;
    }

    function clearSessionItem(key) {
        if (hasSessionStorage) {
            sessionStorage.removeItem(key);
        }
    }

    function status() {
        return $http.get(url('status'));
    }

    // Replacement for $http.put which gzip compresses the data.
    function gzPut(url, data) {
        return gzRequest('PUT', url, data);
    }

    // Replacement for $http.post which gzip compresses the data.
    function gzPost(url, data) {
        return gzRequest('POST', url, data);
    }

    // Do a post/put request with possibly gzipped data. If there is no
    // compression we just send the original data.
    function gzRequest(method, url, data) {
        const uncompressedJson = angular.toJson(data, false);
        const compressedJson = pako.gzip(uncompressedJson);

        let request = {
            method: method,
            url: url,
            transformRequest: []
        };

        if (compressedJson.length < uncompressedJson.length) {
            request.headers = {
                'Content-Type': 'application/json',
                'Content-Encoding': 'gzip'
            };
            request.data = compressedJson;

            //$log.debug(`${method} ${url}: ${uncompressedJson.length} -> ${compressedJson.length} ${100 * (1 - (compressedJson.length / uncompressedJson.length))}% saved`);
        }
        else {
            request.data = uncompressedJson;

            //$log.debug(`${method} ${url}: ${uncompressedJson.length} -> ${compressedJson.length} sending uncompressed`);
        }
        return $http(request);
    }
}
}());
