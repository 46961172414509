;(function() {
"use strict";

angular
    .module('tmr')
    .factory('Tenant', Tenant);

const nsw = {
    isNSW: true,
    isQLD: false,
    title: "Byron Tweed Household Travel Survey",
    support: {
        name: 'survey helpline',
        phone: '1800 659 596',
        email: 'tweedbyrontravelsurvey@roymorgan.com',
        hours: '12&ndash;8pm',
    },
    travelDay: 'travel day',
    dateFormat: 'EEEE d MMMM y',
    undertaking: 'carrying out',
    startTime: '4am',
    eg: 'for example',
};

const qld = {
    isNSW: false,
    isQLD: true,
    title: "Queensland Travel Survey",
    support: {
        name: 'Queensland Travel Survey Team',
        phone: '1800 465 603',
        email: 'qldtravelsurvey@roymorgan.com',
        hours: '12:00pm &ndash; 8:00pm',
    },
    travelDay: 'Travel Day',
    dateFormat: 'EEEE, MMMM d, y',
    undertaking: 'undertaking',
    startTime: '4:00 AM',
    eg: 'e.g.',
};

/* @ngInject */
function Tenant(User, $location, $sce) {
    // We may or may not be logged in. If we are, use the project info.
    // If we are not, use the location.
    // TODO: do we need both?
    let isNSW = false;

    if (User.isLoggedIn()) {
        const project = User.projectInfo();
        isNSW = (project.sample_area_id == 'btnsw');
    }
    else {
        const regex = /^tweed-byron-hts(-staging)?\.websurvey\.au$/;
        isNSW = regex.test($location.host());
    }

    const tenant = isNSW ? nsw : qld;

    // These contain html tags or escapes and need to be used in a tag like
    //   <span ng-bind-html="tenant.support.hours">
    tenant.support.hours = $sce.trustAsHtml(tenant.support.hours);

    return tenant;
}
}());
