;(function() {
"use strict";

const trainingBannerComponent = {
  templateUrl: 'components/training-banner.html',
  controller: trainingBannerController,
  controllerAs: 'vm',
};

/* @ngInject */
function trainingBannerController(User) {
  const vm = this;

  vm.isTraining = User.projectInfo().is_training;

  User.subscribe(() => {
    vm.isTraining = User.projectInfo().is_training;
  });
}

angular
  .module('tmr')
  .component('trainingBanner', trainingBannerComponent);
}());
