;(function() {
"use strict";

angular
    .module('tmr')
    .factory('Navigation', Navigation);

/* @ngInject */
function Navigation($state, $stateParams, $window, SaveModal, User) {
    let service = {};

    _.extend(service, {
        next           : next,
        prev           : prev,
        wrapNext       : wrapNext,
        wrapPrev       : wrapPrev,
        navigate       : navigate,
        logout         : logout,
        home           : home,
        submit         : submit,
        refusalSubmit  : refusalSubmit,
        redirectToHome : redirectToHome,
        redirectToCompleted: redirectToCompleted,
        redirectToDeleted: redirectToDeleted,
        backToAdminApp : backToAdminApp,
    });

    return service;

    function next(stateParams = {}) {
        navigate('next', stateParams);
    }

    function prev(stateParams = {}) {
        navigate('prev', stateParams);
    }

    function wrapNext(beforeNavFunc, stateParams = {}) {
        return function() {
            beforeNavFunc();
            next(stateParams);
        }
    }

    function wrapPrev(beforeNavFunc, stateParams = {}) {
        return function() {
            beforeNavFunc();
            prev(stateParams);
        }
    }

    function home() {
        SaveModal.save({ canAbort: true }, 'home').then(redirectToHome);
    }

    function logout() {
        SaveModal.save({ canAbort: true }, 'logout').then(redirectToSaved);
    }

    function submit() {
        SaveModal.save({ isSubmit: true }, 'submit')
                 .then(redirectToCompleted);
    }

    function refusalSubmit() {
        SaveModal.save({ isSubmit: true }, 'refused')
                 .then(redirectToCompleted);
    }

    function navigate(state, stateParams = {}) {
        let toState = getStateFromData(state) || state;

        if (stateParams.state) {
            // Don't clobber the existing state, merge it in.
            _.extend(stateParams.state, $stateParams);
        }
        else {
            stateParams.state = $stateParams;
        }

        SaveModal.saveAndGo(toState, stateParams);
    }

    function getStateFromData(page) {
        return $state.current.data[page];
    }

    function redirectToHome() {
        $state.go('home');
    }

    function redirectToSaved() {
        if (User.isAdmin()) {
            backToAdminApp(false);
        }
        else {
            $state.go('saved');
        }
    }

    function redirectToCompleted() {
        if (User.isAdmin()) {
            backToAdminApp(false);
        }
        else {
            $state.go('completed');
        }
    }

    function redirectToDeleted() {
        $state.go('deleted');
    }

    function backToAdminApp(doSave = true) {
        if (doSave) {
            SaveModal.save({ canAbort: true }, 'logout').then(ok => {

                // Now call backToAdminApp again, but with doSave = false
                // We can't just fall through, because we're no longer in
                // backToAdminApp itself - we're in the .then of the save modal
                // promise. Doing this saves us repeating the logout logic
                // below.
                backToAdminApp(false);
            });

            return;
        }


        if (User.isLoggedIn()) {
            let redirectUrl = User.getData('admin_redirect_url');
            User.logout();
            $window.location.href = redirectUrl;
        }
        else {
            $window.history.back();
        }
    }
}
}());
