;(function() {
"use strict";

angular
  .module('tmr')
  .controller('HomeController', HomeController);

/* @ngInject */
function HomeController($scope, $state, Animation, User, Validator, SurveyStatus, Navigation, ConfirmationModal, InvalidDataModal, Tenant) {
  let adminPage = User.getData('adminPage');
  let homePage  = User.getData('homePage');
  let constant  = User.getData('const');

  // There's nothing in this page, and nothing to validate, but adding this
  // (and an empty form in the html) helps us detect if the user has been here
  // or not.
  let validator = new Validator($scope, 'homeForm');

  $scope.$on('back-to-admin-app', (event) => {
    beforeNav();

    let hasInvalidData = validator.hasErrors;

    InvalidDataModal.show(hasInvalidData).then(ok => {
      Navigation.backToAdminApp(User.isLoggedIn());
    });

  });

  _.extend($scope, {
    people:             adminPage.people,
    admin:              adminPage,
    travelDate:         constant.date,
    isTravelDay:        isTravelDay,
    gotoHouseholdPage:  gotoHouseholdPage,
    gotoTravelPage:     gotoTravelPage,
    surveyA:            SurveyStatus.surveyA(),
    surveyB:            SurveyStatus.surveyB(),
    overallStatus:      SurveyStatus.overall(),
    prev:               Navigation.wrapPrev(beforeNav),
    logout:             logout,
    scrollToAnchor:     Animation.scrollToAnchor,
    submit:             submit,
    tenant:             Tenant,
    user:               User,
  });

  function submit() {
    let options = {
      noMessage: 'Return to survey',
      yesIcon: "",
      noIcon: ""
    };

    let surveyComplete = SurveyStatus.overall().complete;
    let message = !surveyComplete ? 'Survey is not finished, submit anyway?'
      : 'Are you sure you want to submit?';

    options.yesMessage = !surveyComplete ? 'Submit anyway' : 'Submit';
    options.information = !surveyComplete ? `Your survey may not be finished for one of these reasons:<br><br>- A household member's 'Part B' isn't complete.<br>- You 'shared' trips with another household member, but their travel diary hasn't been fully completed.<br><br>You can submit the survey, but if you remember some details you missed, you can enter the survey again using your original login details for up to two weeks after your ${ Tenant.travelDay }.`
      : `You can submit the survey, but if you remember some details you missed, you can enter the survey again using your original login details for up to two weeks after your ${ Tenant.travelDay }.`;

    ConfirmationModal.confirm(message, options)
      .then(submitted => Navigation.submit());
  }

  function isTravelDay() {
    // scope.travelDate is of the form YYYY-MM-DD
    // We can add the time and timezone to that before parsing.
    // Using moment.js now, because each browser implements its own wackyparse
    let travelTime = moment($scope.travelDate + "T04:00:00+1000");
    let now = moment();

    return now.isSameOrAfter(travelTime);
  }

  function gotoHouseholdPage(page) {
    beforeNav();
    Navigation.navigate(page);
  }

  function gotoTravelPage(person, link) {
    beforeNav();
    Navigation.navigate(link, { state: { personId: person.id } });
  };

  function logout() {
    beforeNav();
    Navigation.logout();
  }

  function beforeNav() {
    homePage.completionStatus = validator.status;
  }
}
}());
