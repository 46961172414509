;(function() {
"use strict";

angular.module('tmr').directive('googlePlace', function() {
    function link(scope, element, attrs, model) {
        var options = {
            // https://gh.sdintra.net/Client-TMR/survey/issues/991
            fields: [
              'place_id',
              'formatted_address',
              'geometry',
              'name',
            ],
            types: [],
            componentRestrictions: {country: 'au'}
        };

        element.on('change', function() {
            scope.$apply(function() {
                scope.geocodeData = undefined;
            });
        });

        // The google maps api might not have been loaded yet.
        // If it has, we can set it up immediately.
        // If it has not, insert our setup code into the initMap chain. That
        // will get called once the api has been loaded.
        if (window.hasOwnProperty('google')) {
            setupAutocomplete();
        }
        else {
            // We may have already installed an initMap handler. Capture it,
            // and chain onto it if we have.
            const previousInitMap = window.initMap;
            window.initMap = function() {
                // If we get here, the google maps api has been loaded, and
                // the `google` object exists.  Call our setup code that needs
                // the google object.
                setupAutocomplete();

                if (previousInitMap) {
                    // Call the previous handler, if there was one.
                    previousInitMap()
                }
            };
        }

        function setupAutocomplete() {
            const gPlace = new google.maps.places.Autocomplete(element[0], options);

            // For a brief time, the "We haven't found a match" text appears.
            // Can't work out how to fix that as yet.
            google.maps.event.addListener(gPlace, 'place_changed', function() {
                scope.geocodeData = gPlace.getPlace();
                scope.$apply(function() {
                    model.$setViewValue(element.val());
                    model.$commitViewValue();
                });
            });
        }
    }

    return {
        require : 'ngModel',
        link    : link,
        scope   : {
            geocodeData: '=googlePlaceGeocodeData',
        }
    };
});
}());
