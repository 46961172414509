;(function() {
"use strict";

/*
    Alert modal. AlertModal("Show this message to the user").
*/

angular
    .module('tmr')
    .factory('AlertModal', AlertModal);

/* @ngInject */
function AlertModal($modal) {
    return function(message, options = {}) {
        var controller = ['$scope', '$modalInstance',
            function (scope, modalInstance) {
                scope.message = message;
                scope.closeButtonText = options.closeButtonText || "Close";
                scope.close = function() {
                  modalInstance.close(true);
                };
            }
        ];

        var params = {
            templateUrl: "partials/modals/alert-modal.html",
            controller: controller,
        };

        return $modal.open(params).result;
    };
}
}());
