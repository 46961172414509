;(function() {
"use strict";

angular
    .module('tmr')
    .controller('VehiclesController', VehiclesController);

/* @ngInject */
function VehiclesController($scope, Animation, $document, $timeout, Navigation, User, Validator, ConfirmationModal, InvalidDataModal, Tenant, UniqueId) {
    let pageData = User.getData('vehiclesPage');
    _.defaults(pageData, {
        vehicles: [ ],
        bicycleCount: 0,
    });

    let validator = new Validator($scope, 'vehiclesForm', pageData.showValidity);

    function hasVehicles() {
        return pageData.vehicles.length > 0;
    }

    $scope.$on('back-to-admin-app', (event) => {
        beforeNav();

        let hasInvalidData = validator.hasErrors;

        InvalidDataModal.show(hasInvalidData).then(ok => {
            Navigation.backToAdminApp(User.isLoggedIn());
        });
    });

    _.extend($scope, {
        pageData:                pageData,
        nextPage:                nextPage,
        prevPage:                Navigation.wrapPrev(beforeNav),
        addVehicle:              addVehicle,
        tenant:                  Tenant,
    });

    function nextPage(skipValidations) {
        pageData.completionStatus = validator.status;

        // Short circuit modals for home button
        if (skipValidations) {
            beforeNav();
            Navigation.home();
            return;
        }

        // If page is in a good, completed state, move on
        if (hasVehicles() && validator.isComplete) {
            doNext();
            return;
        }

        // Show a modal with different messages depending on the
        // current state of the vehicles page. One for no vehicles
        // and the other for partially-completed vehicles.
        let message;
        let options;
        if (hasVehicles() && !validator.isComplete) {
            message = "It looks like we are missing some information about your vehicles.";
            options = {
                yesMessage:  "I know, proceed anyway",
                noMessage: "Go back and review",
                yesIcon:    "",
                noIcon:     "",
            };
        } else { // No vehicles
            message = "Are you sure that your household does not own or use any vehicles (including company cars and/or motorcycles)? You can return to this page at a later time if you need to."
            options = {
                yesMessage: "No vehicles to add",
                noMessage:  "Actually, I need to add some vehicles now",
                yesIcon:    "",
                noIcon:     "",
            };
        }

        // Move on if yes, close modal if no.
        ConfirmationModal.confirm(message, options).then(
            yesNone => doNext(),
        );
    }


    // Next action marks vehicles as completed now.
    function doNext() {
        pageData.completionStatus = validator.status;
        pageData.showValidity     = true;
        Navigation.next();
    }

    let vehicles = pageData.vehicles;
    _.each(pageData.vehicles, v => {
        if (_.isUndefined(v.vehicleId)) {
            v.vehicleId = nextVehicleId();
        }
    });

    _.each(pageData.vehicles,
            Animation.watchAccordionRowChanges($scope));

    function nextVehicleId() {
        return UniqueId.getUniqueId('vehicle', vehicles, 'vehicleId');
    }

    function createVehicle() {
        return {
            vehicleId: nextVehicleId(),
            isOpen:    false,
        };
    }

    function addVehicle() {
        var openVehicle = _.find(vehicles, v => v.isOpen);
        if (openVehicle && vehicles.length != 0) {
            openVehicle.isOpen = false;
        }

        let newVehicle;
        let newVehicleIndex;

        // Scroll to bottom of accordion
        let lastVehicleIndex = vehicles.length - 1;
        Animation.scrollToAccordionIndex(lastVehicleIndex, 1000)
        .then(Animation.wait(250))
        .then(() => {
            vehicles.push(createVehicle());
            newVehicle      = _.last(vehicles);
            newVehicleIndex = vehicles.length - 1;
        })
        .then(Animation.wait(500))
        .then(function() {
            let setupWatcher = Animation.watchAccordionRowChanges($scope);
            setupWatcher(newVehicle, newVehicleIndex);
        })
        .then(Animation.wait(250))
        .then(() => {
            newVehicle.isOpen = true;
        });

    }

    function beforeNav() {
        pageData.showValidity     = true;
        pageData.completionStatus = validator.status;
    }

    function onVehicleEvent(eventName, handler) {
        $scope.$on(`vehicle-${eventName}`, (e, vehicle) => {
            e.stopPropagation();
            handler(e, vehicle);
        });
    }

    onVehicleEvent('delete', (e, vehicle) => {
        var message = "Are you sure you want to delete this vehicle?";
        var options = {
            yesMessage: "Yes, delete it",
            noMessage:  "No, keep it",
            yesIcon:    "fa-trash-o",
            noIcon:     "",
        };

        ConfirmationModal.confirm(message, options)
            .then(function() { _.arrayRemove(vehicles, vehicle) });
    });

}
}());
