;(function() {
"use strict";

angular.module('tmr').directive('questionStatus', ['$log',
function($log) {

    var directive = {
        require: '^^form',
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/question-status.html',
        link: link,
        scope: {
            inputName    :  '@',
            neverVisible :  '='
        },
    };

    function link(scope, element, attrs, form) {
        function isVisible() {
            return form.showValidity
                || form[scope.inputName].$touched
                || form[scope.inputName].$dirty;
        }

        var showWarning = false ;

        function getStatus() {
            var input = form[scope.inputName];
            if (!input) {
                if (showWarning) {
                    // Don't show this warning the first time, as the input
                    // won't have been created yet.
                    $log.error(`cannot find input "${scope.inputName}"`);
                }
                showWarning = true;
                return 'notfound';
            }

            // Radio buttons and checkboxes are never visible
            // so checking if has a value, and value is valid
            if (scope.neverVisible && input.$valid) {

                // setTouched is done here so when a checkbox
                // or radio button is unchecked, it will
                // display the warning icon. "touched"ness of
                // radio buttons and checkboxes is done on an
                // individual bases, not as a group :/

                // We may actually be looking at a form, which has no
                // $setTouched method.
                if (_.has(input.$setTouched)) {
                    input.$setTouched();
                }
                return 'tmr-complete'
            }

            if (!isVisible())                      { return 'hidden'     }
            if (input.$valid)                      { return 'complete'   }
            if (input.$error.error)                { return 'invalid'    }
            return                                          'incomplete';
        }


        const statusMap = {
            complete: {
                'icon': 'check',
                'description': 'This question is complete',
                'class': 'tmr-complete',
            },
            incomplete: {
                'icon': 'exclamation-circle',
                'description': 'This question is incomplete',
                'class': 'tmr-incomplete',
            },
            invalid: {
                'icon': 'times',
                'description': 'This question is invalid',
                'class': 'tmr-invalid',
            },
            notfound: {
                'icon': 'question',
                'class': 'tmr-notfound',
            },
            hidden: {},
        };


        scope.statusDescription = () => statusMap[getStatus()].description;
        scope.statusClass       = () => statusMap[getStatus()].class;
        scope.statusIcon        = () => statusMap[getStatus()].icon;
    }

    return directive;
}]);
}());
