;(function() {
"use strict";

angular
  .module('tmr')
  .filter('fullDate', FullDateFilter);

// @ngInject
function FullDateFilter($filter, Tenant) {
  const dateFilter = $filter('date');

  return function(date) {
    return dateFilter(date, Tenant.dateFormat);
  };
}
}());
