;(function() {
"use strict";

angular
    .module('tmr')
    .controller('CommentsController', CommentsController);

/* @ngInject */
function CommentsController($scope, $stateParams, Animation, User, Navigation, Options, PersonAge, FieldValidation, Tenant) {
    let constant    = User.getData('const');
    let project     = User.projectInfo();
    let personId    = $stateParams.personId;
    let admin       = User.getData('adminPage');
    let person      = _.findWhere(admin.people, { id: personId });

    let birthYear = _.getPath(person, 'birthYear.id');
    let age         = PersonAge.getAgeRange(birthYear);

    // This is the default setup.
    let showBTNSWQuestions           = false;
    let showCovidQuestions           = false;
    let showHomeShoppingQuestions    = false;
    let showFiftyCentFareQuestions   = false;
    let showFurtherResearchQuestions = true;
    let showIncomeQuestions          = true;
    let showNQStadiumQuestions       = false;
    let showUberTaxiQuestions        = false;
    let showWorkFromHomeQuestions    = _.getPath(person, 'work.isJob', false);
    let incomeQuestionOptional       = '';

    // Per-sample-area customisations
    if (project.sample_area_id === 'seqts') {
        // nothing needed yet
    }
    else if (project.sample_area_id === 'cairns') {
        incomeQuestionOptional = ' OPTIONAL QUESTION';
    }
    else if (project.sample_area_id === 'tvts') {
        showNQStadiumQuestions = true;
    }
    else if (project.sample_area_id === 'btnsw') {
        showBTNSWQuestions           = true;
        showFurtherResearchQuestions = false;
        showIncomeQuestions          = false;
    }

    // Finer-grained per-project customisations
    if (project.code === 'seqts.2020-2021') {
        showCovidQuestions        = true;
        showHomeShoppingQuestions = true;
    }

    // We probably want to check: (project.code === 'seqts.2024-2025')
    // In order to make this testable, I'll just limit it to seqts for now.
    // We can add a cut-off date (or check the actual project) later on.
    //
    if (project.sample_area_id === 'seqts') {
        // For the seqts 2024/5 survey, if there were public transport trips,
        // and the travel date is >= 2024/08/05, ask the 50c fare question.
        const startDate = new Date('2024-08-05');
        const travelDate = new Date(constant.date);

        if (travelDate >= startDate) {
            // We use the mode.hasRoutes flag to indicate if this a PT trip or
            // not. This excludes chartered buses and private school buses.
            // Given that we're talking about fares, this is likely correct.
            // If not we can add a new attribute to the modes table.
            const tripList = _.getPath(person, 'tripList', [ ]);
            const hasPublicTransportTrip = _.some(tripList,
                trip => _.getPath(trip, 'how.mode.hasRoutes', false));

            if (hasPublicTransportTrip) {
                showFiftyCentFareQuestions = true;
            }
        }
    }

    // The above is hard to test. If you set a date in the future, you can't do
    // your travel diary. I'm going to add a separate thing here for the example
    // project in staging.
    if (project.code.startsWith('example')) {
        const tripList = _.getPath(person, 'tripList', [ ]);
        const hasPublicTransportTrip = _.some(tripList,
            trip => _.getPath(trip, 'how.mode.hasRoutes', false));

        if (hasPublicTransportTrip) {
            showFiftyCentFareQuestions = true;
        }
    }

    let isOver18 = false;
    let maybe18 = false;
    let adultMinAge = 16;
    if (age.unknown) {
        // The person is yet to specify a year of birth,
        // so we do not ask the question.

        // nothing to do here
    } else if (age.max < 18) {
        // The person is definitely under eighteen.
        if (age.max < adultMinAge) {
            showIncomeQuestions = false;
        }
    } else if (age.min >= 18) {
        // The person is definitely at least eighteen.
        isOver18 = true;
    } else {
        // The person may be seventeen or eighteen
        maybe18 = true;
    }


    $scope.$on(`back-to-admin-app`, (event) => {
        Navigation.backToAdminApp(User.isLoggedIn());
    });

    if (_.isUndefined(person) || person.underFive) {
        Navigation.redirectToHome();
        return;
    }

    _.defaults(person, {
        questions: { },
    });
    person.questions.completionStatus = 'complete';

    if (admin.people[0].id == person.id) {
        if (_.isUndefined(person.questions.email)) {
            if (admin.email) {
                person.questions.email = admin.email;
            }
        }
    }

    // If the person didn't travel, directly go to travel page
    function back() {
        if (person.travel.noTrips) {
            Navigation.navigate('travel');
        }
        else {
            Navigation.prev();
        }
    }

    // Export data items into the scope
    _.extend($scope, {
        name:           person.name,
        questions:      person.questions,
        nextPage:       Navigation.next,
        prevPage:       back,
        saveAndHome:    Navigation.home,
        isOver18:       isOver18,
        maybe18:        maybe18,
        scrollToAnchor: Animation.scrollToAnchor,
        isValidEmail:   FieldValidation.email,
        incomeQuestionOptional: incomeQuestionOptional,
        tenant:         Tenant,

        showBTNSWQuestions:           showBTNSWQuestions,
        showCovidQuestions:           showCovidQuestions,
        showFiftyCentFareQuestions:   showFiftyCentFareQuestions,
        showFurtherResearchQuestions: showFurtherResearchQuestions,
        showHomeShoppingQuestions:    showHomeShoppingQuestions,
        showIncomeQuestions:          showIncomeQuestions,
        showNQStadiumQuestions:       showNQStadiumQuestions,
        showUberTaxiQuestions:        showUberTaxiQuestions,
        showWorkFromHomeQuestions:    showWorkFromHomeQuestions,
    });

    // Annotate some of the optional questions with an easy way for the exporter
    // to include or exclude them.
    _.defaults(person.questions, { is_valid: { } });
    _.extend(person.questions.is_valid, {
        no_of_rideshare_trips:              showUberTaxiQuestions,
        rideshare_trip_purpose:             showUberTaxiQuestions,
        no_of_taxi_trips:                   showUberTaxiQuestions,
        taxi_trip_purpose:                  showUberTaxiQuestions,

        work_from_home_on_travel_day:       showWorkFromHomeQuestions,
        work_from_home_days_past_week:      showWorkFromHomeQuestions,
        work_from_home_before_coronavirus:  showCovidQuestions,

        home_shopping:                      showHomeShoppingQuestions,
        nq_stadium:                         showNQStadiumQuestions,
        income:                             showIncomeQuestions,
        btnsw:                              showBTNSWQuestions,
        fifty_cent_fares:                   showFiftyCentFareQuestions,
    });

    $scope.$watch('questions.income', (newValue, oldValue) => {
        // If the user selects an income answer that prompts the secondary
        // question, then selects another that hides it again, clear the
        // hidden-again answer.
        if (newValue && !newValue.keep_asking) {
            person.questions.income_again = undefined;
        }
    });

    $scope.btnswShowPurposesReasons = true;
    $scope.$watch('questions.btnsw.other_transport_modes', (newValue, oldValue) => {
        $scope.btnswShowPurposesReasons = !_.any(newValue, item => item.none_of_the_above);
    });

    let opt = Options.makeOption;

    $scope.uberTaxiTripOptions = Options.makeNumbers(0, 29, "30+");

    $scope.purposeOptions = [
        opt('work', 'Go to or from work / for work purposes'),
        opt('school', 'Go to or from school / Uni / TAFE'),
        opt('shopping', 'Go to or from shopping'),
        opt('entertainment', 'Go to or from entertainment / social events'),
        opt('healthcare', 'Go to or from healthcare / medical services'),
        opt('other', 'Other reason'),
    ];

    $scope.workFromHomeDays = Options.makeNumbers(0, 7);
    $scope.homeShoppingOptions = [
        opt('food',
            'Supermarket groceries or fruit and vegetable delivery'),
        opt('meals',
            'Pre-prepared meals, including takeaway'),
        opt('clothing-electrical',
            'Clothing, footwear, electrical or technology items'),
        opt('other',
            'Any other packages'),
    ];

    $scope.mostRecentYears = [
        opt('2020', '2020'),
        opt('2021', '2021')
    ];

    $scope.eventTypes = [
        opt('concert', 'Concert'),
        opt('football', 'Football Game'),
        opt('other_sporting', 'Other Sporting'),
        opt('other', 'Other (Please specify)'),
    ];

    $scope.travelModes = [
        opt('vehicle_as_driver', 'Vehicle as driver'),
        opt('vehicle_as_passenger', 'Vehicle as passenger'),
        opt('event_shuttle_bus', 'Event Shuttle bus'),
        opt('regular_timetabled_bus', 'Regular timetabled bus service'),
        opt('uber_or_taxi', 'Uber or Taxi'),
        opt('bicycle', 'Bicycle'),
        opt('walk', 'Walk'),
    ];

    $scope.incomeOptions = Options.makeSelect([
        opt('negative_or_nil',  'Nil or negative income'),
        opt('pw_0001_0149',     '$1 - $149 per week ($1 - $7,799 per year)'),
        opt('pw_0150_0299',     '$150 - $299 per week ($7,800 - $15,599 per year)'),
        opt('pw_0300_0399',     '$300 - $399 per week ($15,600 - $20,799 per year)'),
        opt('pw_0400_0399',     '$400 - $499 per week ($20,800 - $25,999 per year)'),
        opt('pw_0500_0649',     '$500 - $649 per week ($26,000 - $33,799 per year)'),
        opt('pw_0650_0799',     '$650 - $799 per week ($33,800 - $41,599 per year)'),
        opt('pw_0800_0999',     '$800 - $999 per week ($41,600 - $51,999 per year)'),
        opt('pw_1000_1249',     '$1,000 - $1,249 per week ($52,000 - $64,999 per year)'),
        opt('pw_1250_1499',     '$1,250 - $1,499 per week ($65,000 - $77,999 per year)'),
        opt('pw_1500_1749',     '$1,500 - $1,749 per week ($78,000 - $90,999 per year)'),
        opt('pw_1750_1999',     '$1,750 - $1,999 per week ($91,000 - $103,999 per year)'),
        opt('pw_2000_2999',     '$2,000 - $2,999 per week ($104,000 - $155,999 per year)'),
        opt('pw_3000_3499',     '$3,000 - $3,499 per week ($156,000 - $181,999 per year)'),
        opt('pw_3500_plus',     '$3,500 or more per week ($182,000 or more per year)'),
        opt('not_stated',       'Prefer not to say / can\'t say', { keep_asking: true }),
    ]);

    $scope.extraIncomeOptions = [
      opt('pw_above_1250', 'More than'),
      opt('pw_under_1250', 'Less than'),
      opt('still_not_stated', 'Prefer not to say'),
    ];

    $scope.yesNoOptions = Options.yesNoOptions;

    $scope.btnswOtherTransportModes = [
        opt('public_bus',          'Public bus'),
        opt('train',               'Train'),
        opt('walk',                'Walk'),
        opt('bicycle',             'Push bike'),
        opt('ebike',               'Electric bike'),
        opt('escooter',            'Electric scooter'),
        opt('skate',               'Scooter/skateboard'),
        opt('mobility_device',     'Personal mobility device'),
        opt('taxi',                'Taxi or ride share (including limousine/hire car)'),
        opt('motorcycle',          'Motorbike'),
        opt('lift',                'Get a lift with friends or family'),
        opt('courtesy_bus',        'Courtesy bus'),
        opt('community_transport', 'Community transport'),
        opt('none',                'None (I do not have another option to travel)', { none_of_the_above: true }),
        opt('other',               'Other (please specify)'),
    ];

    $scope.btnswOtherTransportPurposes = [
        opt('word',           'Travel to work'),
        opt('errands',        'To run errands (e.g., shopping, going to the bank, haircut etc.)'),
        opt('events',         'Travel to events (e.g., sports games, concerts etc.)'),
        opt('children',       'Taking children to and from day care or school'),
        opt('eat_drink_out',  'To eat or drink out'),
        opt('social_service', 'Travel to social service appointments (e.g., Centrelink, Service NSW)'),
        opt('medical',        'Travel to medical appointments'),
        opt('other',          'Other (please specify)'),
    ];

    $scope.btnswOtherTransportReasons = [
        opt('convenience',  'It is not convenient to use my car for these journeys (lack of parking, congestion)'),
        opt('faster',       'It is faster not to use my car'),
        opt('cheaper',      'It is cheaper not to use my car'),
        opt('health',       'Not using my car is better for my health'),
        opt('wellbeing',    'Not using my car is better for my sense of wellbeing'),
        opt('environment',  'Not using my car is better for the environment'),
        opt('no_car',       'Not relevant - I do not have access to a car'),

        // We don't get here if they have no car
        //opt('must_use_car', 'Not relevant - I have no option other than to use my car'),
    ];

    $scope.btnswFutureTravelOptions = [
        opt('much_less',     'I would like to travel by car much less often'),
        opt('slightly_less', 'I would like to travel by car slightly less often'),
        opt('same',          'I would not like to change my travel habits'),
        opt('slightly_more', 'I would like to travel by car slightly more often'),
        opt('much_more',     'I would like to travel by car much more often'),
    ];

    $scope.btnswPublicTransportEncouragements = [
        opt('lack_of_parking',    'Restrictions or lack of available parking at my destination'),
        opt('closer_services',    'Public transport services or routes closer to where I live'),
        opt('more_reliable',      'More reliable public transport services'),
        opt('better_connecting',  'Better connecting services'),
        opt('better_parking',     'Better parking facilities at stations/bus stops'),
        opt('more_frequent',      'More frequent public transport services'),
        opt('new_or_faster',      'New or faster services to reduce travel times'),
        opt('electric_buses',     'Electric buses'),
        opt('cheaper_fares',      'Cheaper or subsidised fares'),
        opt('improved_safety',    'Improved safety and security when using public transport'),
        opt('better_cleanliness', 'Better cleanliness on public transport services'),
        opt('better_information', 'Better access to information on public transport options (timetables, routes, fares)'),
        opt('prizes', 'Make it fun- rewards, prizes or competitions for using public transport'),
        opt('better_health',      'Better social distancing / COVID safety'),
        opt('none',               'None of the above, I would not consider using public transport', { none_of_the_above: true }),
        opt('other',              'Other (please specify)'),
    ];

    $scope.btnswWalkRideEncouragements = [
        opt('lack_of_parking',    'Restrictions or lack of available parking at your destination'),
        opt('better_pathways',    'Improved pathways, crossings and lighting along my journey'),
        opt('better_storage',     'Better access to safe storage of bikes and scooters at my destination'),
        opt('charging_stations',  'Electric cycle/scooter charging stations at key destinations'),
        opt('better_information', 'Better cycle route maps / directional signage / additional information'),
        opt('better_facilities',  'Better end of trip facilities at my destination (lockers, changerooms and showers)'),
        opt('better_safety',      'Improved safety and security along my journey'),
        opt('prizes',             'Make it fun- rewards, prizes or competitions for using public transport'),
        opt('none',               'None of the above, I would not consider walking or riding over car use', { none_of_the_above: true }),
        opt('other',              'Other (please specify)'),
    ];

    $scope.fiftyCentFaresOtherModeOptions = [
        opt('private_vehicle_driver',    'Private vehicle driver'),
        opt('private_vehicle_passenger', 'Private vehicle passenger'),
        opt('taxi_ride_share',           'Taxi or Uber/Ride Share'),
        opt('bicycle_walking',           'Bicycle, Walking'),
        opt('no_trip',                   'Would not have made the trips'),
    ];
}
}());
