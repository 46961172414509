;(function() {
"use strict";

angular
    .module('tmr')
    .factory('DeleteSurveyModal', DeleteSurveyModal);

/* @ngInject */
function DeleteSurveyModal($log, $modal, $q, $timeout, Navigation, Tenant, User) {

    const controller = ['$scope', '$modalInstance',
        function (scope, modalInstance) {
            function cancelButton() {
                modalInstance.dismiss('cancel button');
            }

            function deleteButton() {
                console.log("deleting....", scope);
                scope.isBusy = true;
                scope.message = " - deleting";

                let reason = scope.data.reason;
                User.deleteSurvey(reason.id).then(
                    success => {
                        scope.message = " - survey deleted";
                        $timeout(2000).then(function() {
                            modalInstance.close(true);
                            Navigation.backToAdminApp(false);
                        });
                    },
                    failure => {
                        scope.message = " - delete failed, please try again";
                        scope.isBusy = false;
                    });
            }

            let reasons = [
                {
                    id: "requested",
                    text: "Householder has decided they no longer wish to participate in the " + Tenant.title,
                },
                {
                    id: "mistake",
                    text: "This survey was entered by mistake",
                },
            ];

            var constant = User.getData('const');

            // Just use the address from the admin app. That's where they've
            // come from if they got here, so we want the very same address.
            let address = _.getPath(constant, 'person.address');

            _.extend(scope, {
                data: { },
                cancelButton,
                deleteButton,
                reasons,
                address,
            });
        }
    ];

    const params = {
        templateUrl: 'partials/modals/delete-survey-modal.html',
        controller:  controller,
    };

    function deleteSurvey() {
        return $modal.open(params).result.then(
            success => {
                console.log("deleteSurvey success", success);
                return success;
            },
            failure => {
                console.log("deleteSurvey failure", failure);
                return $q.reject(failure); // re-throw the failure
            });
    }

    return {
        deleteSurvey,
    };
}
}());
