;(function() {
"use strict";

angular.module('tmr').filter('keyword', function() {

    // The model elements of the array look like this:
    //  [ "Display string", [ "keyword", "another", "more" ] ]
    //
    // The idea is that the user can type in (prefixes of) searchwords, and
    // these are matched against the keywords.
    //
    // For an element to be selected, all of the searchwords must match
    // against its keywords (we want the search to get narrower with more
    // search terms).
    //
    // In the example above "an mor" would match, but "an mor wo" wouldn't.

    function filter(array, searchText) {
        if (_.isUndefined(searchText)) {
            return array;
        }

        var searchwords = searchText.toLowerCase().split(/\s+/);

        function isMatch(element) {
            var keywords = element[1];

            // Each searchword must be a prefix of at least one of the
            // keywords
            //
            return _.every(searchwords, prefix =>
                        _.some(keywords, keyword =>
                            keyword.startsWith(prefix)));
        }

        return _.filter(array, isMatch);
    }

    return filter;
});
}());
