;(function() {
"use strict";

const tmrLegendComponent = {
    templateUrl: 'components/tmr-legend.html',
    controller: tmrLegendController,
    controllerAs: 'vm',
    bindings: {
        hideComplete:   '@',
        hideIncomplete: '@',
        hideRequired:   '@',
    },
};

function tmrLegendController() {
}

angular
    .module('tmr')
    .component('tmrLegend', tmrLegendComponent);
}());
