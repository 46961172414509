;(function() {
"use strict";

window.tmr= {
    "code_version":         "development",
    "rollbar_access_token": "37665682c5854d2ab4a97f82823c715d",
    "rollbar_active_for_dev": false,
    "environment":
        (window.location.hostname.match( /tmr-tst-([^.]+)\.sd-demo\.com/ )||[])[1] // staging and testing
        || (/travelsurvey\.tmr\.qld\.gov\.au/.test(window.location.hostname) && 'production')
        || 'development'

};
window.tmr.code_version='6408844b65eb32179c10b8c1c38cc77ea9f69aa1';
}());
