;(function() {
"use strict";

/* Manage unique ids for different things in the system.
 *
 * eg. let nextVehicleId = UniqueId.next('vehicle'); // returns "vehicle1001"
 *     let nextPersonId  = UniqueId.next('person');  // returns "person1001"
 */
angular
    .module("tmr")
    .factory("UniqueId", UniqueId);

/* @ngInject */
function UniqueId(User) {
    let service = { };

    let data = User.getData('uniqueIds');

    _.extend(service, {
        getUniqueId:       getUniqueId,
    });

    return service;

    // This function has two bugs, which cancel each other out.
    // The idea is that type will be a string like "vehicle" or "person", and
    // we'll keep a running tally of the id for each one.
    //
    // eg. { person: 1001, vehicle: 1002 }
    //
    // Unfortunately, stephent is a dumb-arse, and this isn't what we get.
    // In all three cases below, the bareword `type` does not refer to the
    // parameter, rather the literal string "type".
    //
    // What we in fact have looks more like this:
    //
    // eg. { type: 1003 }
    //
    // This still does what we want. Each time we call it, we will get a fresh
    // id. We could change this to dereference the type parameter correctly,
    // but I'm concerned this could break existing surveys. Let's just leave it
    // as it is.
    //
    // Note that we still use the provided type string as a prefix to the
    // numeric id.
    //
    // This function is now purely internal. Use getUniqueId below instead.
    function next(type) {
        _.defaults(data, { type: 1000 });

        let id = type + data.type;
        data.type++;
        return id;
    }

    // We've had problems with duplicate ids. I have NFI where those are coming
    // from, but rather than blindly accepting a duplicate id, lets check
    // against what we have already, and make sure it's actually unique.
    function getUniqueId(type, existingObjects, idKey) {
        // Create a list of the existing keys. We don't expect to be searching
        // this more than once, so no need to get too fancy here.
        let usedIds = _.pluck(existingObjects, idKey);

        //console.log(`Existing ${type} ids:`, usedIds);

        while (true) {
            let id = next(type);
            if (! _.contains(usedIds, id)) {
                //console.log(`New id: ${id}`);
                return id;
            }

            //console.log(`ID ${id} already in use - retrying`);
        }
    }
}
}());
