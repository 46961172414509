;(function() {
"use strict";

/*
 * This directive is a shortcut set of <input type="checkbox"> controls that
 * takes * an array of objects as the options.
 *
 * The items in the array must have be of the form:
 *  {
 *      id:     "uniqueId",     // valid JS identifier
 *      text:   "Display text",
 *  }
 *
 * The model is an array of the selected option objects..
 *
 * usage: <tmr-checkbox model="" name="" options=""><tmr-radio>
 *
 */
angular.module('tmr').directive('tmrCheckbox', function (BooleanAttribute) {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: "directive/tmr-checkbox.html",
        transclude: true,
        link: link,
        scope: {
            // Required fields
            model:      '=ngModel',
            name:       '@',
            options:    '=',

            // Optional fields
            optional:      '@',
            nested:        '=',
            maxSelections: '<',
        },
    };

    function link(scope, element, attrs) {
        scope.isOptional = BooleanAttribute(attrs.optional);
        var options = _.indexBy(scope.options, 'id');
        const maxSelections = _.isUndefined(attrs.maxSelections) ?
           scope.options.length : Number.parseInt(attrs.maxSelections);

        function convertInternalToExternal(internal) {
            // { one: true, two: false } -> [ { id: one } ]
            return _.chain(internal)
                     .map((isSet, id) => isSet && options[id])
                     .compact()
                     .value();
        }

        function convertExternalToInternal(external) {
            // [ { id: one } ] -> { one: true }
            return _.chain(external)
                    .pluck('id')
                    .map(id => [ id, true ])
                    .object()
                    .value();
        }

        function handleNoneOfTheAbove(external) {
            if (external.length == 0) {
                return;
            }

            // The last thing click will be at the end of the array.
            // If it has none_of_the_above set, we should remove everything
            // else. Otherwise, we've clicked a not none-of-the-above, and we
            // should clear out any none-of-the-aboves.
            // This makes the 'none-of-the-above' work as you probably expect.
            const last = _.last(external);
            if (last.none_of_the_above) {
                // If there's more than one, filter the rest
                if (external.length > 1) {
                    return _.filter(external, item => item.none_of_the_above);
                }
            }
            else if (_.some(external, item => item.none_of_the_above)) {
                // Only update if there's ones to filter out.
                return _.reject(external, item => item.none_of_the_above);
            }

            return;
        }

        scope.$watch('model', (newValue, oldValue) => {
            if (angular.equals(newValue, oldValue)) {
                return;
            }

            const newerModel = handleNoneOfTheAbove(newValue);
            if (newerModel) {
                // Update the external model, which will trigger another $watch
                scope.model = newerModel;
                return;
            }

            if (newValue.length > maxSelections) {
                const toDrop = newValue.length - maxSelections;
                scope.model = _.rest(newValue, toDrop);
                return;
            }

            // Update the internal model
            scope.internalModel = convertExternalToInternal(newValue);
        });

        scope.internalModel = convertExternalToInternal(scope.model);
        scope.onChange = function() {
            var newModel = convertInternalToExternal(scope.internalModel);
            scope.model = newModel;
        };

        scope.anyChecked = function() {
            return scope.isOptional || scope.model && scope.model.length > 0;
        }

    }
    return directive;
});
}());
