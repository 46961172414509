;(function() {
"use strict";

angular
    .module('tmr')
    .directive('typeahead', typeahead);

/* @ngInject */
function typeahead($timeout) {
    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/typeahead.html',
        link: link,
        require: '?^tmrQuestion',
        scope: {
            // Required fields
            model:      '=ngModel',
            data:       '=',

            // Optional fields
            maxResults: '@',
            placeholder: '@',
        },
    };

    function link(scope, element, attrs, tmrQuestion) {
        if (_.isObject(tmrQuestion)) {
            scope.inputName = tmrQuestion.inputName;
        }

        angular.extend(scope, {
            labelFor:         labelFor,
            keyHandler:       keyHandler,
            selectItem:       selectItem,
            isSelected:       isSelected,
            hasSuggestions:   hasSuggestions,
            showSuggestions:  false,
            hideSuggestions:  hideSuggestions,
            currentSelection: 0,
            updateCurrentSelection: updateCurrentSelection,
        });

        function hideSuggestions(ms = 500) {
          $timeout(() => scope.showSuggestions = false, ms);
        }

        function labelFor(index) {
            // Label is the first index of the array
            return scope.suggestions[index][0];
        }

        function selectItem(index) {
            if (scope.suggestions[index]) {
                scope.model = labelFor(index);
            }
            scope.showSuggestions = false;
        }

        function updateCurrentSelection(index) {
            if (index <= scope.suggestions.length - 1 && index >= 0) {
                scope.currentSelection = index;
            }
        }

        function keyHandler(evt) {
            switch(evt.keyCode) {
                case 40: // down
                    updateCurrentSelection(scope.currentSelection + 1);
                    break;
                case 38: // up
                    updateCurrentSelection(scope.currentSelection - 1);
                    break;
                case 13: // enter
                    evt.preventDefault();
                    selectItem(scope.currentSelection);
                    break;
                case 9: // tab
                    if (scope.showSuggestions === true && scope.model) {
                        selectItem(scope.currentSelection);
                    }
                    break;
                case 27: // esc
                    scope.showSuggestions = false;
                    break;
                default:
                    scope.currentSelection = 0;
                    scope.showSuggestions  = true;
            }
        }

        function isSelected(index) {
            return (index == scope.currentSelection);
        }

        function hasSuggestions() {
            let suggestions = scope.suggestions;
            return (suggestions && suggestions.length > 0 && scope.showSuggestions);
        }
    }

    return directive;
}
}());
