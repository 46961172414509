;(function() {
"use strict";

/*
 * This directive is a shortcut set of <input type="radio"> controls that takes
 * an array of objects as the options.
 *
 * The items in the array must have be of the form:
 *  {
 *      id:     "uniqueId",     // valid JS identifier
 *      text:   "Display text",
 *  }
 *
 * The model will be set to the selected object.
 *
 * usage: <tmr-radio model="" name="" options=""><tmr-radio>
 *
 */
/* @ngIject */
angular.module('tmr').directive('tmrRadio', function(BooleanAttribute) {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: "directive/tmr-radio.html",
        transclude: true,
        scope: {
            // Required fields
            model:        '=ngModel',
            name:         '@',
            options:      '=',

            // Optional fields
            nested:       '@',
            change:       '&',
            optional:     '@',
        },
        link: function(scope, element, attr) {
            scope.isOptional = BooleanAttribute(attr.optional);

            scope.$watch('model', (newValue, oldValue) => {
                if (!angular.equals(newValue, oldValue)) {
                    scope.change({ $event: {newValue,oldValue}});
                }
            });
        },
    };

    return directive;
});
}());
