;(function() {
"use strict";

/*
 * This is a retry-wrapper around user.save
 *
 * Call it like this:
 *
 *      saveModal.save(canAbort).then(
 *          ok    => {
 *              save_succeeded()
 *          },
 *          abort => {
 *              if (abort) {
 *                 save_aborted();     // continue without saving
 *              }
 *              else {
 *                  save_cancelled();   // go back
 *              },
 *      );
 *
 *
 * Specify canAbort=true if it makes sense for the user to proceed even if the
 * save failed. eg. save & exit - if the backend dies the user should stil be
 * able to log out, even if they do lose their work.
*/

angular
    .module('tmr')
    .factory('SaveModal', SaveModal);

/* @ngInject */
function SaveModal($log, $modal, $q, $state, $timeout, $window, User) {

    function hasExpired(response) {
        return response.status == 403;
    }

    function isConflict(response) {
        return response.status == 409;
    }

    function doSave(options) {

        var defaults = {
            retryMessage:   "Try again",
            abortMessage:   "Exit without saving",
            cancelMessage:  "Go back",
            reloadMessage:  "Reload survey",

            retryIcon:      "fa-refresh",
            cancelIcon:     "fa-reply",
            abortIcon:      "fa-exclamation-triangle",
            reloadIcon:     "fa-frown-o",
        };

        var message = {
            conflict: "Someone else has saved your survey while you were working on it. Please press the 'Reload survey' button to recover from the last save. Your most recent changes will be lost.",
            failed:   "Save failed. Try again?",
            saving:   "Saving ...",
            saved:    "Save succeeded",
        };

        function setFailure(scope, failure) {
            if (isConflict(failure)) {
                scope.message = message.conflict;
                scope.canRetry = false;
            }
            else {
                scope.message = message.failed;
                scope.canRetry = true;
            }
            scope.canReload = !scope.canRetry;
            scope.canCancel = scope.canRetry;
        }

        var firstFailure; // this gets set before the dialog is called

        var controller = ['$scope', '$modalInstance',
            function (scope, modalInstance) {
                _.extend(scope, defaults);

                setFailure(scope, firstFailure);
                scope.canAbort = options.canAbort;

                scope.retryButton = function() {
                    scope.isBusy = true;
                    scope.message = message.saving;
                    // Add in a short delay here, just so the user can see the
                    // retrying message.
                    $timeout(500).then(() => {
                        User.save(options).then(
                            ok => {
                                scope.message = message.saved;
                                // Another delay so the user can see the success
                                // message
                                $timeout(500).then(() =>
                                    modalInstance.close(true));
                            },
                            failure => {
                                if (hasExpired(failure)) {
                                    $log.debug("Session expired. Closing.");
                                    scope.cancelButton();
                                }
                                $log.warn("last failure", failure);
                                setFailure(scope, failure);
                                scope.isBusy = false;
                            }
                        );
                    });
                };

                scope.abortButton = function() {
                    $log.debug("save aborted");
                    modalInstance.close(false);
                };

                scope.cancelButton = function() {
                    $log.debug("save cancelled");
                    modalInstance.dismiss(true);
                };

                scope.reloadButton = function() {
                    // There's no return from this. We need to completely
                    // reload our survey from the database. The easiest and
                    // safest way to do that is to just reload the page.
                    $log.debug("reloading the survey");
                    $window.location.reload();
                }
            }
        ];

        var params = {
            templateUrl: "partials/modals/save-modal.html",
            controller: controller,
        };

        $log.debug("Saving");

        // This is the main entry point to the whole shebang. Typically the
        // save will succeed, and this returns the promise. The failure case
        // is where it gets interesting. That's where we pop the modal and
        // offer to retry etc. Through the magic of promises, it all works out
        // the same to the calling code: they get given a promise which either
        // succeeds or fails.
        return User.save(options).catch(
            failure => {
                if (hasExpired(failure)) {
                    $log.debug("Session expired. Cancelling.");
                    return $q.reject(failure);
                }
                $log.debug("Initial save failed, launching dialog");
                firstFailure = failure;
                $log.warn("last failure", failure);
                return $modal.open(params).result;
            }
        );
    };

    function save(options = { }, toState, personId) {
        options.fromState = $state.current.name;
        if (toState) {
            options.toState = toState;
        }
        if (personId) {
            options.personId = personId;
        }

        return doSave(options).catch(failure => {
            if (!User.isLoggedIn()) {
                $state.go('expired');
            }
            return $q.reject(failure);
        });
    }

    function saveAndGo(state, options = { }) {
        _.defaults(options, {
            save: { },
            state: { },
        });

        return save(options.save, state, options.state.personId).then(() => {
            $state.go(state, options.state);
        });
    }

    return {
        save:       save,
        saveAndGo:  saveAndGo,
    };
}
}());
