;(function() {
"use strict";

const tmrSkipLinkComponent = {
    templateUrl: 'components/tmr-skip-link.html',
    controller: tmrSkipLinkController,
    controllerAs: 'vm',
    bindings: {
      scrollToId: '@',
    },
};

/* @ngInject */
function tmrSkipLinkController(Animation) {
  let vm = this;
  vm.scrollToContent = () => Animation.scrollToAnchor(vm.scrollToId);
}

angular
    .module('tmr')
    .component('tmrSkipLink', tmrSkipLinkComponent);
}());
