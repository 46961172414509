;(function() {
"use strict";

const tmrIconComponent = {
    transclude: true,
    templateUrl: 'components/tmr-icon.html',
    controller: tmrIconController,
    controllerAs: 'vm',
    bindings: {
        icon:    '@',
        meaning: '@',
    },
};

function tmrIconController() {
}

angular
    .module('tmr')
    .component('tmrIcon', tmrIconComponent);
}());
