;(function() {
"use strict";

angular
    .module('tmr')
    .controller('AdminController', AdminController);

/* @ngInject */
function AdminController($scope, Navigation, UniqueId, User, Validator, Animation, ConfirmationModal, InvalidDataModal, AlertModal, DeleteSurveyModal, Options, $timeout, FieldValidation, Tenant) {
    $scope.version = window.tmr.code_version;

    var adminPage = $scope.adminPage = User.getData('adminPage');

    let showValidity = adminPage.showValidity;
    let validator    = new Validator($scope, 'adminForm', showValidity);

    var constant = User.getData('const');
    $scope.travelDate = new Date(constant.date);

    // These projects should show the refusal questions. seqts.2021-2022 and
    // any subsequent ones should not.
    let refusalQuestionsProjects = [
        'example.2019-2020',
        'seqts.2017-2019',
        'seqts.2020-2021',
        'tvts.2020-2021',
        'twb.2019-2020',
    ]
    $scope.useRefusalQuestions = _.contains(refusalQuestionsProjects,
        constant.project.code);

    // This is so we can unset househould completion status
    // when a user returns and adds a new person.
    let personAdded = false;

    $scope.$on('back-to-admin-app', (event) => {
        let hasInvalidData = validator.hasErrors;

        adminPage.isComplete = validator.isComplete;

        InvalidDataModal.show(hasInvalidData).then(ok => {
            Navigation.backToAdminApp(User.isLoggedIn());
        });
    });

    // Set up the default values.
    _.defaults(adminPage, {
        respondentRefused: false,
    });

    if (! _.has(adminPage, 'people')) {
        // This used to be in the defaults section, until I realised that
        // createPerson() gets called every time, regardless of whether we
        // need to or not.
        adminPage.people = [ createPerson() ];
    }

    function createPerson() {
        let person = {
            id:         UniqueId.getUniqueId('person', adminPage.people, 'id'),
            name:       "",
            underFive: false,
        };

        return person;
    }

    function addPerson() {
        personAdded = true;
        adminPage.people.push(createPerson());
    }

    function deletePerson(person) {
        var message = "Are you sure you want to delete this person?";
        var options = {
            yesMessage: "Yes, delete it",
            noMessage:  "No, keep it",
            yesIcon:    "fa-trash-o",
            noIcon:     "",
            information: "You will lose any data you have entered for this person",
        };

        ConfirmationModal.confirm(message, options).then(yes => {
            adminPage.people = _.without(adminPage.people, person);

            // Deleting a person can make a duplicate name go away.
            validateNames();
        });
    }

    function personList() {
        // If the lone person household option is selected, we only show one
        // person, but if more already existed, we don't delete them, just hide
        // them.
        if (singlePersonHousehold()) {
            return [ adminPage.people[0] ];
        }
        return adminPage.people;
    }

    function singlePersonHousehold() {
        return adminPage.householdType && adminPage.householdType.singlePerson;
    }

    function canAddPerson() {
        return !singlePersonHousehold();
    }

    function canDeletePerson() {
        return !singlePersonHousehold() && (adminPage.people.length > 1);
    }

    function startSurvey() {
        // They've returned to this page and added somebody.
        // Reset household section!
        if (adminPage.isComplete && personAdded) {
            let household              = User.getData('householdPage');
            household.completionStatus = 'incomplete';
        }

        adminPage.showValidity = true;

        if (adminPage.respondentRefused) {
            Navigation.refusalSubmit();
        }
        else {
            // Here we remove any hidden people.
            adminPage.people = personList();

            Navigation.next();
        }
    }

    function validateNames() {
        function isUnique() {
            let seen = { }
            for (const person of personList()) {
                if (_.isUndefined(person.name)) {
                    continue;
                }
                let name = person.name.toLowerCase();
                if (seen[name]) {
                    return false;
                }
                seen[name] = true;
            }
            return true;
        }

        $scope.allNamesUnique = isUnique();

        return $scope.allNamesUnique;
    }

    function canStartSurvey() {
        return validator.isComplete;
    }

    function nextPage() {
        adminPage.isComplete = validator.isComplete;
        if (!canStartSurvey()) {
            validator.showValidity = true;

            $timeout(250).then(() => {
                let firstError = _.first(document.getElementsByClassName('question-status tmr-incomplete'));
                Animation.scrollToId(firstError.id).then(() => firstError.focus());
            });
        }
        else {
            startSurvey();
        }
    }


    let opt = Options.makeOption;
    let genderOptions = [
        opt('male',   'Male'),
        opt('female', 'Female'),
        opt('other',  'Other'),
        opt('cannotdetermine',  'Cannot determine'),
    ];

    let ageOptions = [
        opt('under-16',         'Under 16 years'),
        opt('16-to-24',         '16-24 years'),
        opt('25-to-34',         '25-34 years'),
        opt('35-to-44',         '35-44 years'),
        opt('45-to-54',         '45-54 years'),
        opt('55-to-64',         '55-64 years'),
        opt('65-or-over',       '65 years or over'),
        opt('cannotdetermine',  'Cannot determine'),
        opt('refused',          'Refused'),
    ];


    function generateRefusalOptions(min, max) {
        let refusalNumberOptions = _.range(min, max).concat(max + "+");
        let refusalOtherOptions  = [
            'Cannot Determine',
            'Refused',
        ];
        let adminQuestionOptions = Options.makeSelect(
            refusalOtherOptions.concat(refusalNumberOptions)
        );

        return adminQuestionOptions;
    }

    let householdRefusalOptions = generateRefusalOptions(1, 20);
    let vehicleRefusalOptions   = generateRefusalOptions(0, 20);

    let allNamesUnique = true;

    // Inject the exported functions into the scope
    _.extend($scope, {
        ageOptions:              ageOptions,
        genderOptions:           genderOptions,
        householdRefusalOptions: householdRefusalOptions,
        vehicleRefusalOptions:   vehicleRefusalOptions,
        addPerson:               addPerson,
        canAddPerson:            canAddPerson,
        canDeletePerson:         canDeletePerson,
        deletePerson:            deletePerson,
        canDeleteSurvey:         User.hasPermission('delete'),
        deleteSurvey:            DeleteSurveyModal.deleteSurvey,
        validateNames:           validateNames,
        canStartSurvey:          canStartSurvey,
        isValidEmail:            FieldValidation.email,
        nextPage:                nextPage,
        personList:              personList,
        scrollToAnchor:          Animation.scrollToAnchor,
        user:                    User,
        allNamesUnique:          allNamesUnique,
        tenant:                  Tenant,
    });

    $scope.householdType = [
        opt('lone',   'Lone', { singlePerson: true }),
        opt('family', 'Family'),
        opt('shared', 'Shared'),
    ];

}



/*
    Note: this was a to-do item

    * hide 'How many people' & 'under 5' questions for lone person household

    This tends to give suprising behaviour.
    eg.
        1. select "One family household"
        2. add and name three people
        3. switch to "Lone person household"

    What should happen there? You've got three people listed, with no way of
    reducing that. Or you reduce the list to one, which is surprising.

    The other option perhaps is to disable the 'Lone person household' if the
    person count is not one.
*/
}());
