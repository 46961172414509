;(function() {
"use strict";

/*
 * Validation logic for a single page (or sub-page).
 *
 * let validator = new Valiadator(scope, "formName");
 *
 */
angular
    .module('tmr')
    .factory('Validator', Validator);


function Validator() {
    // For debugging use - make the validation things always appear.
    let showValidityAlways = false;

    // Typically when one of these objects is constructed, the form hasn't
    // been attached to the scope yet. Rather than festoon the code with
    // tons of 'if (this.form)' guards, we use this. It gives sensible answers
    // until the real form is ready.
    class Validator {
        constructor(scope, formName, showValidity = false) {
            this.form = {
                $valid:       false,
                $error:       { error: false },
                showValidity: showValidityAlways || showValidity,
            };

            let unregister = scope.$watch(formName, value => {
                if (value) {
                    // If validity was set against the temporary form obj, copy
                    // across to the *real* form
                    let showValidity       = this.form.showValidity;
                    this.form              = value;
                    this.form.showValidity = showValidityAlways || showValidity;
                    unregister();
                }
            });
        }

        get isComplete() {
            return this.form.$valid;
        }

        // We don't want to include $error.required as an error here, as that's
        // not a validation error, rather a missing-data error.
        //
        // If we find any key other than 'required', that means we have a
        // validation error.
        get hasErrors() {
            if (_.findKey(this.form.$error, (val, key) => key != 'required')) {
                //console.log("hasErrors:", angular.copy(this.form.$error));
                return true;
            }
            //console.log("hasErrors: NO!");
            return false;
        }

        get showValidity() {
            return this.form.showValidity;
        }

        set showValidity(value) {
            this.form.showValidity = value;
            return value;
        }

        get status() {
            if (this.isComplete)      { return 'complete'     }
            if (this.hasErrors)       { return 'invalid'      }
            return                             'incomplete';
        }

        get statusClass() {
            return this.showValidity ? this.status : '';
        }
    }

    return Validator;
}
}());
