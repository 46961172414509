;(function() {
"use strict";

angular
  .module('tmr')
  .controller('SavedController', SavedController);

/* @ngInject */
function SavedController(User) {
  // Make sure the user gets logged out when we save and logout
  User.logout();
}
}());
