;(function() {
"use strict";

const tmrModalComponent = {
    transclude: true,
    replace: true,
    templateUrl: 'components/tmr-modal.html',
    controller: tmrModalController,
    controllerAs: 'vm',
    bindings: {
        template: '@',
    },
};

/* @ngInject */
function tmrModalController(ModalFactory, $element) {
    let vm = this;

    vm.openModal = openModal;

    function openModal() {
        let modal  = ModalFactory.open(vm.template);
        let origin = $element[0].querySelector('span.modal-link');

        modal.result.then(
            closed  => origin.focus(),
            dismiss => origin.focus(),
        );
    }
}

angular
    .module('tmr')
    .component('tmrModal', tmrModalComponent);
}());
