;(function() {
"use strict";

const tmrExitButtonComponent = {
    transclude: true,
    templateUrl: 'components/tmr-exit-button.html',
    controller: tmrExitButtonController,
    controllerAs: 'vm',
};

/* @ngInject */
function tmrExitButtonController($rootScope, $state, Navigation, User) {
    let vm = this;

    angular.extend(vm, {
        saveAndBack:  saveAndBack,
        isEnabled:    isEnabled,
    });

    function isEnabled() {
        return User.isAdmin();
    }

    function saveAndBack() {
        $rootScope.$broadcast('back-to-admin-app');
    }

}

angular
    .module('tmr')
    .component('tmrExitButton', tmrExitButtonComponent);
}());
