;(function() {
"use strict";

angular.module('tmr').directive('tmrStepper', function() {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/stepper.html',
        link: link,
        scope: {
            value: '=ngModel',
            min: '@',
            max: '@',
        },
    };

    function link(scope, element, attrs) {
        if (_.isUndefined(scope.value)) {
            // Leave this undefined. That's how we determine if the control
            // has been touched by the user.
            // scope.value = scope.min;
        }
        else if (scope.value < scope.min) {
            scope.value = scope.min;
        }
        else if (scope.value > scope.max) {
            scope.value = scope.max;
        }
        scope.increment = function() {
            initialiseValue(scope.min - 1);
            scope.value++;
        }
        scope.decrement = function() {
            initialiseValue(scope.min + 1);
            scope.value--;
        }

        function initialiseValue(defaultValue) {
            if (_.isUndefined(scope.value)) {
                scope.value = defaultValue;
                return true;
            }
            return false;
        }

        // Update the values of min and max whenever they change
        var min, max;
        attrs.$observe('min', str => { min = parseInt(str) });
        attrs.$observe('max', str => { max = parseInt(str) });

        // min & max will either be numbers, undefined or NaN
        // When undefined or NaN these tests will always return false, which
        // is what we want.
        scope.atMin = function() { return scope.value <= min };
        scope.atMax = function() { return scope.value >= max };

        scope.isValid = function() {
            return !_.isUndefined(scope.value);
        }

        scope.displayValue = function() {
            return _.isUndefined(scope.value) ? "-" : scope.value;
        }
    }

    return directive;

});
}());
