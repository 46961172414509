;(function() {
"use strict";

angular
    .module('tmr')
    .directive('person', person);

/* @ngInject */
function person(OccupationData, Options, Validator, SaveModal, Animation, User, PersonAge, Tenant) {
    let constant = User.getData('const');
    let adminPage = User.getData('adminPage');

    let directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/person.html',
        link: link,
        scope: {
            person:         '=',
            firstPerson:    '=',
            isFirst:        '=',
        },
    };

    function link(scope, element, attrs) {
        const tripDate = new Date(constant.date);
        const tripYear = tripDate.getFullYear();

        let validator = new Validator(scope, 'personForm', scope.person.showValidity);
        scope.person.showValidity = true;
        scope.tenant = Tenant;

        function anySelected(checkboxes) {
            return _.some(_.values(checkboxes));
        }

        function hasOther(checkboxes) {
            return _.findWhere(checkboxes, { hasOther: true });
        }

        function updateUnderFive() {
            let year = _.getPath(scope.person, 'birthYear.id');
            let age = PersonAge.getAgeRange(year);

            if (age.unknown) {
                // The person is yet to specify a year of birth. We don't even
                // ask the under five question yet.
                scope.person.underFive = false;
            }
            else if (age.max < 5) {
                // The person is definitely under five.
                scope.person.underFive = true;
            }
            else if (age.min >= 5) {
                // The person is definitely at least five.
                scope.person.underFive = false;
            }
            else {
                // The person may be four or five, so we ask.
                scope.person.underFive =
                    _.getPath(scope.person, 'confirmsUnderFive.value');
            }
        }

        let adultMinAge = 16;

        // Note: isAdult & isChild are not the inverse of one another.
        // Neither one is true if the birth year hasn't been selected.
        function isAdult() {
            let year = _.getPath(scope.person, 'birthYear.id');
            let age = PersonAge.getAgeRange(year);
            if (age.unknown) {
                return false;
            }

            // even if a person may be 15 we consider him as adult
            return age.max >= adultMinAge;
        }

        function isChild() {
            let year = _.getPath(scope.person, 'birthYear.id');
            let age = PersonAge.getAgeRange(year);
            if (age.unknown) {
                return false;
            }

            return age.max < adultMinAge;
        }

        function maybeUnder5() {
            let year = _.getPath(scope.person, 'birthYear.id');
            let age = PersonAge.getAgeRange(year);
            if (age.unknown) {
                return false;
            }

            return age.max == 5;
        }

        function isSpouse() {
            let relation = _.getPath(scope.person, 'relationship.id');
            return relation == 'spouse';
        }

        function isParent() {
            let relation = _.getPath(scope.person, 'relationship.id');
            return relation == 'parent';
        }

        function isMarkedAsChild() {
            let relation = _.getPath(scope.person, 'relationship.id');
            return relation == 'child';
        }

        function isSpouseOver16() {
            // Validate that someone under 16 is not marked as a spouse.
            // Returns true if validation is successful.
            if (isSpouse() && isChild()) {
                return false;
            }
            else {
                return true;
            }
        }

        function isParentAgeValid() {
            // Validate that parents are elder than children.
            // Returns true if validation is successful.
            updateUnderFive();

            if (isParent()) {
                let firstPerson = _.first(adminPage.people);

                let year = _.getPath(firstPerson, 'birthYear.id');
                let firstPersonAge = PersonAge.getAgeRange(year);
                if (firstPersonAge.unknown) {
                    return true;
                }

                year = _.getPath(scope.person, 'birthYear.id');
                let currentPersonAge = PersonAge.getAgeRange(year);
                if (currentPersonAge.unknown) {
                    return true;
                }

                return firstPersonAge.min < currentPersonAge.min;
            }

            return true;
        }

        function isChildAgeValid() {
            // Validate that children are younger than parent.
            // Returns true if validation is successful.
            updateUnderFive();

            if (isMarkedAsChild()) {
                let firstPerson = _.first(adminPage.people);

                let year = _.getPath(firstPerson, 'birthYear.id');
                let firstPersonAge = PersonAge.getAgeRange(year);
                if (firstPersonAge.unknown) {
                    return true;
                }

                year = _.getPath(scope.person, 'birthYear.id');
                let currentPersonAge = PersonAge.getAgeRange(year);
                if (currentPersonAge.unknown) {
                    return true;
                }

                return firstPersonAge.min > currentPersonAge.min;
            }

            return true;
        }

        function isParentOver5() {
            // Validate that someone 5 or under is not marked as a parent.
            // Returns true if validation is successful.

            updateUnderFive();

            if (scope.person.underFive) {
                let relation = _.getPath(scope.person, 'relationship.id');
                return relation != "parent";
            }
            else {
                return true;
            }

        }

        function isAtleastOneOver5() {
            let allUnderFive = _.every(adminPage.people, (person) => person.underFive);

            if (allUnderFive) {
                return false;
            }
            else {
                return true;
            }
        }

        function displayErrors() {
            if (!validator.isComplete) {
                if (validator.showValidity) {
                    Animation.timeout(250).then(() => {
                        let firstError = _.first(document.getElementsByClassName('tmr-incomplete'));
                        Animation.scrollToId(firstError.id).then(() => firstError.focus());
                    });
                }
            }
        }

        function doSave() {
            if (!validator.isComplete) {
                validator.showValidity = true;
            }
            SaveModal.save().then(saved => scope.person.isOpen = false);
        }

        let birthYears = Options.makeYears(1901, tripYear);;

        // Here we inject the closure locals into the scope.
        _.extend(scope, {
            anySelected:            anySelected,
            hasOther:               hasOther,
            updateUnderFive:        updateUnderFive,
            maybeUnder5:            maybeUnder5,
            isAdult:                isAdult,
            isChild:                isChild,
            isSpouseOver16:         isSpouseOver16,
            isParentOver5:          isParentOver5,
            isParentAgeValid:       isParentAgeValid,
            isChildAgeValid:        isChildAgeValid,
            isAtleastOneOver5:      isAtleastOneOver5,
            uniqueId:               scope.$id,
            validator:              validator,

            doSave:                 doSave,
            displayErrors:          displayErrors,

            assistReasonOptions:    assistReasonOptions,
            birthYears:             birthYears,
            adultStudyOptions:      adultStudyOptions,
            childStudyOptions:      childStudyOptions,
            industryOptions:        industryOptions,
            licenceOptions:         licenceOptions,
            relationshipOptions:    relationshipOptions,
            sexOptions:             sexOptions,
            workOptions:            workOptions,
            yesNoOptions:           Options.yesNoOptions,
            noYesOptions:           Options.noYesOptions,

            occupations:            OccupationData.occupations,
        });
    }

    let opt = Options.makeOption;

    let makeSelect = Options.makeSelect;

    //-- data items follow

    let assistReasonOptions = [
        opt('conditionST', 'Short-term health condition (lasting less than six months)'),
        opt('conditionLT', 'Long-term health condition (lasting six months or more)'),
        opt('disability',  'Disability (lasting months or more)'),
        opt('age',         'Old or young age'),
        opt('language',    'Difficulty with English language'),
        opt('other',       'Other cause'),
    ];

    let industryOptions = makeSelect([
        opt('accom',        'Accommodation and Food Services'),
        opt('admin',        'Administrative and Support Services'),
        opt('agriculture',  'Agriculture, Forestry and Fishing'),
        opt('arts',         'Arts and Recreation Services'),
        opt('construction', 'Construction'),
        opt('education',    'Education and Training'),
        opt('utilities',    'Electricity, Gas, Water and Waste Services'),
        opt('financial',    'Financial and Insurance Services'),
        opt('health',       'Health Care and Social Assistance'),
        opt('it',           'Information Media and Telecommunications'),
        opt('mining',       'Mining'),
        opt('manufacturing','Manufacturing'),
        opt('professional', 'Professional, Scientific and Technical Services'),
        opt('publicAdmin',  'Public Administration and Safety'),
        opt('realEstate',   'Rental, Hiring and Real Estate Services'),
        opt('retail',       'Retail Trade'),
        opt('transport',    'Transport, Postal and Warehousing'),
        opt('wholesale',    'Wholesale Trade'),
        opt('other',        'Other Services'),
    ]);

    let licenceOptions = [
        opt('fullLicence',     'Open licence'),
        opt('p2Licence',       'P2 provisional licence (Green)'),
        opt('p1Licence',       'P1 provisional licence (Red)'),
        opt('learnersPermit',  'Learner\'s permit'),
    ];

    let relationshipOptions = [
        opt('spouse',       'Husband, wife or partner'),
        opt('child',        'Child (any age)'),
        opt('parent',       'Parent'),
        opt('relative',     'Other relative'),
        opt('nonFamily',    'Non-family member'),
        opt('other',        'Something else'),
    ];

    let sexOptions = [
        opt('male',     'Male'),
        opt('female',   'Female'),
        opt('other',    'Other'),
    ];

    let adultStudyOptions = [
        opt('secondary',        'Secondary school'),
        opt('tertiaryFullTime', 'Full-time university/TAFE/College'),
        opt('tertiaryPartTime', 'Part-time university/TAFE/College'),
        opt('other',            'Something else'),
    ];

    let childStudyOptions = [
        opt('preschool',        Tenant.isNSW ? 'Childcare' : 'Childcare/Kindergarten'),
        opt('primary',          'Primary school'),
        opt('secondary',        'Secondary school'),
        opt('other',            'Something else'),
    ];

    let workOptions = [
        opt('workFullTime',     'Employed (paid work) typically working 35 hours or more in a week',   { isJob: true }),
        opt('workPartTime',     'Employed (paid work) typically working less than 35 hours in a week', { isJob: true }),
        opt('volunteer',        'Volunteer or unpaid work experience'),
        opt('carer',            'Caring for a child/children, an elderly person(s), or an ill or disabled person(s)'),
        opt('retired',          'Retired or on an age pension'),
        opt('longTermIllness',  'Not currently working due to a long-term health condition or disability'),
        opt('shortTermIllness', 'Not currently working due to a short-term illness or injury'),
        opt('otherReason',      'Not currently working for any other reason'),
    ];

    return directive;
}
}());
