;(function() {
"use strict";

angular
    .module('tmr')
    .factory('SurveyStatus', SurveyStatus);

/* @ngInject */
function SurveyStatus(User) {
    let service = {};

    // Each page data has this key for status
    let completionKey = 'completionStatus';

    // These pages are completed once per survey
    let surveyAPages = [
        { name: 'householdPage', link: 'household' },
        { name: 'vehiclesPage',  link: 'vehicles'  },
    ];

    // Need to be completed once, per person in the household.
    // people underFive don't need to complete these sections.
    let surveyBSections = [
        { name: 'travel',       link: 'travel'   },
        { name: 'trips',        link: 'trips'    },
        { name: 'questions',    link: 'comments' },
    ];

    let completion = {
        notStarted: 0,
        incomplete: 1,
        complete:   2,
    };

    _.extend(service, {
        surveyA:            surveyAStatus,
        surveyB:            surveyBStatus,
        surveyBStatusFor:   surveyBStatusFor,
        overall:            overall,
    });

    return service;

    function getStatus(validities) {
        let status = aggregateStatus(validities);
        return toStatusObj(status);
    }

    function getPageStatus(pageName) {
        return User.getData(pageName)[completionKey];
    }

    function sectionStatusForPerson(person, sectionName) {
        return _.getPath(person[sectionName], completionKey);
    }

    function surveyAStatus() {
        let pageStatus = { };
        _.each(surveyAPages, page => {
            pageStatus[page.name] = getPageStatus(page.name);
        });

        let firstIncomplete = _.find(surveyAPages, page => {
            return pageStatus[page.name] != 'complete'
        }) || surveyAPages[0];

        let status = getStatus(_.values(pageStatus));
        status.link = firstIncomplete.link;
        return status;
    }

    function surveyBStatus() {
        let people = User.getData('adminPage').people;

        return _.chain(people)
                .reject(person => notRequired(person))
                .map(person    => surveyBStatusFor(person))
                .value();
    }

    function overall() {
        let surveyA = surveyAStatus();
        let surveyB = _.pluck(surveyBStatus(), 'complete');

        let status = completion.complete;
        if (!surveyA.complete || _.contains(surveyB, false)) {
             status = completion.incomplete;
        }

        return toStatusObj(status);
    }

    // XXX : This logic is needed elsewhere.
    function notRequired(person) {
        if (person.underFive || person.isDeleted) {
            return true;
        }
        return false;
    }

    function surveyBStatusFor(person) {
        let people = User.getData('adminPage').people;
        let index  = people.indexOf(person);

        if (person.underFive) {
            return 'underfive';
        }

        if (person.isDeleted) {
            return 'deleted';
        }

        let sectionStatus = { };
        _.each(surveyBSections, section => {
            sectionStatus[section.name] =
                                sectionStatusForPerson(person, section.name);
        });

        // Special case for where the person has no trips
        if (_.getPath(person.travel, 'noTrips')) {
            sectionStatus.trips = 'complete';
        }

        let firstIncomplete = _.find(surveyBSections, section => {
            return sectionStatus[section.name] != 'complete'
        }) || surveyBSections[0];

        let status = getStatus(_.values(sectionStatus));
        status.link = firstIncomplete.link;
        return status;
    }

    function toStatusObj(status) {
        let completionStatus = {
            class:      [ 'notstarted',  'incomplete', 'complete'  ],
            adjective:  [ 'Not Started', 'Incomplete', 'Completed' ],
            verb:       [ 'Start',       'Continue',   'Edit'      ],
            complete:   [ false,         false,        true        ],
        };

        let ret = {};

        _.each(completionStatus, (values, key) => {
            ret[key] = values[status];
        });

        return ret;
    }

    function aggregateStatus(validities) {
        // Each element of validities is one of:
        //  [ undefined, "incomplete", "invalid", "complete" ]
        let hist = _.countBy(validities);

        function allStatusEqual(value) {
            return (_.has(hist, value) && hist[value] === validities.length);
        }

        if (allStatusEqual(undefined)) {
            return completion.notStarted;
        }

        if (allStatusEqual('complete')) {
            return completion.complete;
        }

        return completion.incomplete;
    }
}
}());
