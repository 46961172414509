;(function() {
"use strict";

angular
    .module('tmr')
    .factory('InvalidDataModal', InvalidDataModal);

/* @ngInject */
function InvalidDataModal(ConfirmationModal, $q) {

    const message = "This page contains invalid data. If you return to the household details page you will lose the invalid responses.";
    const options = {
        yesMessage: "Back to admin app anyway",
        noMessage:  "Cancel and review survey",
        yesIcon:    "",
        noIcon:     "",
    };

    function show(hasInvalidData) {
        if (hasInvalidData) {
            return ConfirmationModal.confirm(message, options);
        }
        else {
            // Caller expects a promise to be returned.
            return $q.resolve(true);
        }
    };

    return {
        show: show,
    };
}
}());
