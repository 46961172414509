;(function() {
"use strict";

/*
 * This directive is a shortcut <select> that takes an array of objects.
 *
 * The items in the array must have a .label and a unique .id
 * One of the items should have a .isDefault=true attribute. This is used as
 * the placeholder, and is unselectable. This will default to the first option.
 *
 * The items can have an optional .group field, and this will be used for
 * <opt-group>
 *
 * usage: <tmr-select model="selected" options="values"></tmr-select>
 *
 * Optional attribute: change="onChange($event)"
 *
 * This is a callback that passes an object: { newValue: ... , oldValue ... }
 */
angular.module('tmr').directive('tmrSelect', function () {

    var directive = {
        restrict: 'E',
        replace: true,
        require: '?^tmrQuestion',
        templateUrl: "directive/TmrSelect.html",
        scope: {
            model:      "=",
            options:    "=",
            change:     "&",
        },
        link: function(scope, element, attr, tmrQuestion) {
            if (_.isObject(tmrQuestion)) {
              scope.inputName = tmrQuestion.inputName;
              if (attr.idSuffix) {
                  // Adding id-suffix="foo" to a tmr-select control will append
                  // that suffix to the id. Use this if you have multiple
                  // tmr-select controls in the same tmr-question.
                  scope.inputName += attr.idSuffix;
              }
            }

            let defaultOption = _.findWhere(scope.options, { isDefault: true });
            if (_.isUndefined(scope.model)) {
                scope.model = defaultOption;
            }
            if (_.isUndefined(scope.model) && !_.isUndefined(scope.options)) {
                scope.model = scope.options[0];
            }
            if (scope.model && scope.model.isDefault && defaultOption) {
                scope.model = defaultOption;
            }
            scope.$watch('model', (newValue, oldValue) => {
              if (!angular.equals(newValue, oldValue)) {
                scope.change({ $event: { newValue, oldValue }});
              }
            });
        },
    };

    return directive;
});
}());
