;(function() {
"use strict";

/*
 */
angular.module('tmr').directive('tmrAddress', function() {

    var directive = {
        restrict: 'E',
        replace: true,
        require: '?^tmrQuestion',
        templateUrl: "directive/tmr-address.html",
        scope: {
            model:      "=",
            name:       "@",
        },
        link: function(scope, element, attr, tmrQuestion) {
            if (_.isObject(tmrQuestion)) {
              scope.inputName = tmrQuestion.inputName;
            }
        },
    };

    return directive;
});
}());
