;(function() {
"use strict";

angular
  .module('tmr')
  .controller('TenantAwareController', TenantAwareController);

/* @ngInject */
function TenantAwareController($scope, Tenant) {
  $scope.tenant = Tenant;
}
}());
