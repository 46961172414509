;(function() {
"use strict";

angular
    .module('tmr')
    .factory('Animation', Animation);

/* @ngInject */
function Animation($document, $timeout, $q) {
    let service = {};

    angular.extend(service, {
        wait:                     wait,
        timeout:                  timeout,
        scrollToAnchor:           scrollToAnchor,
        scrollToId:               scrollToId,
        afterNextDigest:          afterNextDigest,
        scrollToElement:          scrollToElement,
        scrollToAccordionIndex:   scrollToAccordionIndex,
        watchAccordionRowChanges: watchAccordionRowChanges,
    });

    return service;

    // used by the skip-links
    function scrollToAnchor(id) {
        return scrollToId(id, 250);
    }

    function scrollToId(id, millis = 250) {
        var element = document.getElementById(id);
        if (!element) {
            // No element to scroll to. Just return an already-resolved promise.
            var deferred = $q.defer()
            deferred.resolve(true);
            return deferred.promise;
        }
        return $document.scrollToElement(element, 0, millis);
    }

    function scrollToElement(element, millis = 250) {
        return $document.scrollToElement(element, 0, millis);
    }

    function timeout(ms) {
        return $timeout(ms);
    }

    function wait(ms) {
        return function() { return $timeout(ms) };
    }

    // We use $timeout(fn, 0, false) to make this code run *after* the next
    // digest cycle. Typically we want to use this in the case where we've
    // added an item to an ng-repeat array, and then immediately want to access
    // the associated element. This element won't exist until after the next
    // digest cycle.
    // http://blogs.microsoft.co.il/choroshin/2014/04/08/angularjs-postdigest-vs-timeout-when-dom-update-is-needed/
    function afterNextDigest(func) {
        return $timeout(func, 0, false);
    }

    function scrollToAccordionIndex(index, millis = 250) {
        return scrollToId(`accordion-row-${index}`, millis);
    };

    // Accepts an accordion row, when open, animates nicely to it.
    function watchAccordionRowChanges($scope, preventScroll = false) {
        function setupWatcher(accordionRow, index) {
            $scope.$watch(() => accordionRow.isOpen, function(value, oldValue) {
                if (value && !preventScroll) {
                    afterNextDigest(function () {
                        scrollToAccordionIndex(index);
                    });
                }
                else {
                    accordionRow.isNew = false;
                }
            });
        }

        return setupWatcher;
    }
}
}());
