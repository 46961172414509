;(function() {
"use strict";

const tmrPrivacyModalComponent = {
    transclude: true,
    replace: true,
    templateUrl: 'components/tmr-privacy-modal.html',
    controller: tmrModalController,
    controllerAs: 'vm',
    bindings: {
        template: '@',
    },
};

/* @ngInject */
function tmrModalController(ModalFactory, $element, Tenant) {
    let vm = this;

    vm.openModal = openModal;

    let template = Tenant.isNSW
                    ? "privacy-modal-btnsw.html"
                    : "privacy-modal.html";

    function openModal() {
        let modal  = ModalFactory.open(template);
        let origin = $element[0].querySelector('span.modal-link');

        modal.result.then(
            closed  => origin.focus(),
            dismiss => origin.focus(),
        );
    }
}

angular
    .module('tmr')
    .component('tmrPrivacyModal', tmrPrivacyModalComponent);
}());
