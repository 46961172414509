;(function() {
"use strict";

/*
 * Use this in a <select> tag to add a placeholder prompt that doesn't show up
 * in the drop-down, and disappears once you've selected something.
 *
 * eg. <select prompt="Please choose one"> ... </select>
 *
 */
angular.module('tmr').directive('prompt',
function () {
    var directive = {
        restrict: 'A',
        require: 'select', // must be in a <select tag>
        link: function(scope, element, attrs, selectCtrl) {
            //TODO: This can probably be done with selectCtrl.addOption() or
            //      selectCtrl.emptyOption, but I can't get it working.
            var prompt = attrs.prompt || "Choose one";
            var select = angular.element(element[0]);

            // The key to making this work is to use label=$prompt, and making
            // sure there is no inner html.
            select.prepend(`<option label="${prompt}" value="" disabled selected hidden></option>`);
        },
    };

    return directive;
});
}());
