;(function() {
"use strict";

angular
    .module('tmr')
    .controller('HouseholdController', HouseholdController);

/* @ngInject */
function HouseholdController($scope, Animation, User, Validator, Navigation, ConfirmationModal, InvalidDataModal, Tenant) {
    let household = User.getData('householdPage');
    let admin     = User.getData('adminPage');

    let peopleValidator  = new Validator($scope, 'peopleForm', household.showValidity);

    $scope.$on('back-to-admin-app', (event) => {
        beforeNav();

        let hasInvalidData = peopleValidator.hasErrors;

        InvalidDataModal.show(hasInvalidData).then(ok => {
            Navigation.backToAdminApp(User.isLoggedIn());
        });

    });

    _.extend($scope, {
        people:     admin.people,
        household:  household,
        canDoNext:  canDoNext,
        nextPage:   Navigation.wrapNext(beforeNav),
        prevPage:   Navigation.wrapPrev(beforeNav),
        tenant:     Tenant,
    });

    _.each(admin.people,
            Animation.watchAccordionRowChanges($scope));

    function beforeNav() {
        household.showValidity     = true;
        household.completionStatus = peopleValidator.status;
    }

    function canDoNext() {
        return peopleValidator.isComplete;
    }
}
}());
