;(function() {
"use strict";

angular.module('tmr').directive('specifyOther',
function (BooleanAttribute) {

    var defaultLabel = "Please specify";
    var defaultType= "text";

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/specify-other.html',
        link: link,
        scope: {
            // Required fields
            label:      '@',    // eg. "Please specify other reason"
            inputType:  '@',
            show:       '<',
            optional:   '<',
            model:      '=',
            autoclear:  '=',
        },
    };

    function link(scope, element, attrs) {
        scope.label = attrs.label || defaultLabel;
        scope.inputType = attrs.inputType || defaultType;

        const autoclear = BooleanAttribute(attrs.autoclear);

        function shouldBeShown(x) {
            if (_.isArray(x)) {
                // x is an array of objects. If any have { hasOther: true }, show it
                return _.any(x, item => item.hasOther);
            }
            // Otherwise assume x is a boolean
            return x;
        }

        scope.visible = false;

        scope.$watch('show', (newValue, oldValue) => {
            scope.visible = shouldBeShown(newValue);
            if (autoclear && !scope.visible) {
                scope.model = "";
            }
        }, true);
    }

    return directive;
});
}());
