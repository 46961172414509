;(function() {
"use strict";

angular.module('tmr').filter('pad', function() {
    return function(str='', width=8, fillchar='0') {
        var out = str.toString();
        while (out.length < width) {
            out = fillchar + out;
        }
        return out;
    };
})
}());
