;(function() {
"use strict";

angular
    .module('tmr')
    .factory('FieldValidation', FieldValidation);

/* @ngInject */
function FieldValidation() {

    function email(email) {
        // source: https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
        var pattern = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))?$/;

        // If something is entered then check pattern
        return _.isUndefined(email) || pattern.test(email);
    }

    return {
        email: email,
    };
};
}());
