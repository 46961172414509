;(function() {
"use strict";

angular.module('tmr').directive('tmrQuestion',
function () {

    var directive = {
        restrict: 'E',
        replace: true,
        transclude: {
            extra: '?extra',
        },
        controller: ['$scope', function($scope) {
          let vm = this;
          vm.inputName = 'tmrq' + $scope.$id;
        }],
        controllerAs: 'vm',
        require: '^form',
        templateUrl: function(element, attr) {
            var type      = attr.pageStyle || 'accordion';
            var labelType = attr.grouped ? 'fieldset' : 'label';

            return `directive/tmrQuestion/${type}-${labelType}.html`;
        },
        scope: {
            // Required fields
            label:        '@',    // question text

            // Optional fields
            grouped:      '@',    // if this question requires a fieldset
            instructions: '@',    // instruction text
            tip:          '@',    // tooltip text ("tooltip" is already taken)
            mandatory:    '@',    // is question mandatory
            pageStyle:    '@',    // select the template file
            noStatus:     '@',    // don't display question-status directive
        },
    };

    return directive;
});
}());
