;(function() {
"use strict";

angular
    .module('tmr')
    .directive('flatpickr', flatpickr);

/* @ngInject */
function flatpickr() {
    var directive = {
        restrict: 'E',
        templateUrl: 'directive/flatpickr.html',
        link: link,
        replace: true,
        require: ['?^tmrQuestion', 'ngModel'],
        scope: {
            // Required fields
            model:      '=ngModel',
            data:       '=',
            max:        '@',
        },
    };

    function link(scope, element, attrs, tmrQuestion) {
        var defaultMax = null;

        if (_.isObject(tmrQuestion)) {
            scope.inputName = tmrQuestion.inputName;
        }

        let max = new Date(attrs.max).fp_incr(-1) || defaultMax;

        var fp = new Flatpickr(element[0], {altInput: true, defaultDate: scope.model, maxDate: max});
    }

    return directive;
}
}());
