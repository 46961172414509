;(function() {
"use strict";

angular
    .module('tmr')
    .directive('betweenTripsMarker', BetweenTripsMarker);

/* ngInject */
function BetweenTripsMarker(TripUtils) {

    var directive = {
        restrict: 'E',
        replace: false,
        templateUrl: 'directive/between-trips-marker.html',
        link: link,
        scope: {
            trip: '=',
            next: '=',
        },
    };

    function link(scope, element, attrs) {

        function insertTrip() {
            scope.$emit(scope.error.insertEvent, scope.trip);
        }

        function update() {
            scope.error = TripUtils.checkTripPair(scope.trip, scope.next);
            return !scope.error.isValid;
        }

        // Here we inject the closure locals into the scope.
        _.extend(scope, {
            insertTrip:     insertTrip,
            error:          { },
            update:         update,
        });
    }

    return directive;
}
}());
