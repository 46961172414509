;(function() {
"use strict";

angular
    .module('tmr')
    .factory('TripUtils', TripUtils);


/* @ngInject */
function TripUtils($log) {

    function sameLocation(locA, locB) {
        // Check that its the same type of place
        if (locA.type.id !== locB.type.id) {
            return false;
        }

        // If it has routes and stops, check the stops match
        // Not the routes though - they can be different
        if (locA.type.hasRoutes) {
            // check tht endedTripAt also has Route. (if not we can directly say that user did not end )
            if (!locB.type.hasRoutes) {
                return false;
            }

            // if cant find route is selected, check the descriptions match
            let otherRouteA = locA.location[locA.type.mode].otherRoute;
            let otherRouteB = locB.location[locB.type.mode].otherRoute;
            if (otherRouteA || otherRouteB) {
                if (otherRouteA && otherRouteB) {
                    return (locA.location[locA.type.mode].stopDesc === locB.location[locB.type.mode].stopDesc);
                }
                return false;
            }

            return (locA.location[locA.type.mode].stop === locB.location[locB.type.mode].stop);
        }
        else if (locA.geocodeData && locB.geocodeData) {
            return (locA.geocodeData.place_id === locB.geocodeData.place_id);
        }
        else {
            // Otherwise check the descriptions match
            return (locA.description === locB.description);
        }
    }

    // Possible errors between pairs of trips. Higher severity errors override
    // lower ones.
    const TripPairError = {
        NoError: {
            isValid: true,
            severity: 0,
        },
        TimeOverlap: {
            isValid: false,
            message: "These two trips overlap. Please review the departure and arrival times.",
            explanation: "For some of your journeys, you depart before the previous arrival time.",
            severity: 30,
            markerClass: 'time-overlap',
        },
        LocationJump: {
            isValid: false,
            message: "Have you missed a trip? Review arrival and departure places.",
            explanation: "For some of your journeys, your departure point doesn't match the previous arrival point.",
            severity: 20,
            insertEvent: 'trip-insert-between',
            markerClass: 'location-jump',
        },
        LongStopStay: {
            isValid: false,
            message: "Have you missed a trip? You were here a long time.",
            explanation: "For some of your journeys, you stayed over eight hours at a public transport stop.",
            severity: 10,
            insertEvent: 'trip-insert-after',
            markerClass: 'long-stop-stay',
        },
    };

    // Check an adjacent pair of trips and see if there are any discrepancies.
    // We return the errors in order of severity, most severe first.
    function checkTripPair(prev, next) {
        const noError = { isValid: true };

        // If prev is the last (or only) trip, then we're good.
        if (!next) {
            return TripPairError.NoError;
        }

        const stayMinutes = next.departure.time - prev.arrival.time;

        // The next trip must depart after this one arrived.
        if (stayMinutes < 0) {
            return TripPairError.TimeOverlap;
        }

        // The next trip must depart from the same place the previous one
        // arrived at.
        if (!sameLocation(prev.arrival, next.departure)) {
            return TripPairError.LocationJump;
        }

        // If the trips meet at a public transport stop, there should be a stay
        // of less than eight hours
        const maxStayMinutes = 8 * 60;
        if (prev.arrival.type.hasRoutes && (stayMinutes > maxStayMinutes)) {
            return TripPairError.LongStopStay;
        }

        return noError;
    }

    function checkTripList(trips) {
        let pairs = _.zip(_.initial(trips), _.rest(trips));
        let errors = _.chain(pairs)
                      .map(([ prev, next ]) => checkTripPair(prev, next))
                      .reject('isValid')
                      .value();
        return errors;
    }

    const api = {
        checkTripList,
        checkTripPair,
        sameLocation,
        TripPairError,
    };

    return api;
}
}());
