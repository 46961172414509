;(function() {
"use strict";

angular
    .module('tmr')
    .directive('vehicle', vehicle);

function vehicle(Validator, VehicleData, SaveModal) {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/vehicle.html',
        link: link,
        scope: {
            vehicle: '=',
            index:   '=',
            isModal: '@',
        },
    };

    function link(scope, element, attrs) {
        var validator = new Validator(scope, 'vehicleForm', scope.vehicle.showValidity);
        scope.vehicle.showValidity = true;

        var deselected = { label: 'Select One', id: '', isDefault: true };
        var other      = { label: 'Other', id: 'other', hasOther: true };

        _.extend(scope, {
            data:               VehicleData,
            doSave:             doSave,
            doDelete:           doDelete,
            uniqueId:           scope.$id,
            vehicleName:        vehicleName,
            vehicleMakeChanged: vehicleMakeChanged,
            vehicleTypeChanged: vehicleTypeChanged,
            vehicleYearOptions: makeOptions(VehicleData.years),
            validator:          validator,
        });

        function vehicleName() {
            let vehicle = scope.vehicle;

            // If exists, and id is not null. Selects store a whole obj not just val
            function isSelected(model) {
                return (model && model.id);
            }

            if (isSelected(vehicle.vehicleMake)  &&
                isSelected(vehicle.vehicleModel) &&
                isSelected(vehicle.vehicleYear)) {

                // Have to fetch other values, if other was selected.
                let make  = vehicle.vehicleMake.id  == 'other'  ? vehicle.vehicleMakeOther  : vehicle.vehicleMake.label;
                let model = vehicle.vehicleModel.id == 'other'  ? vehicle.vehicleModelOther : vehicle.vehicleModel.label;
                let year  = vehicle.vehicleYear.id  == 'other'  ? vehicle.vehicleYearOther  : vehicle.vehicleYear.label;
                // Last check to ensure other values are filled in.
                if (make && model && year) {
                    return `${make} ${model} ${year}`;
                }
            }

            return `Vehicle ${scope.index}`;
        }


        function emit(eventName) {
            scope.$emit('vehicle-' + eventName, scope.vehicle);
        }

        function doDelete(event) {
            if (event) {
                event.stopPropagation();
                event.preventDefault();
            }
            emit('delete');
        }

        var idRegex = /[^a-z0-9]/gi;
        function makeId(name) {
            return name.replace(idRegex, '_');
        }

        function makeOptions(names) {
            var options = _.map(names, (name, index) => {
                return {
                    label: name,
                    id: makeId(name),
                };
            });
            if (options.length > 0) {
                options.unshift(deselected);
            }
            options.push(other);

            return options;
        }

        // There are three cascading selectors, vehicle type, make, and model.
        // Selecting higher ones should invalidate the lower ones (eg. if you
        // switch from car to motorcycle, the car makers should be replaced with
        // motorcycle makers).
        // The problem happens at load time where these onChange functions get
        // fired and we inadvertently clear the just-loaded settings.
        // The solution is to check that the type and make have actually
        // changed before clearing them.
        function idChanged(newValue, oldValue) {
            if (_.isUndefined(oldValue)) {
                return !_.isUndefined(newValue);
            }
            // if oldValue is defined, so will newValue
            return oldValue.id != newValue.id;
        }

        function vehicleTypeChanged(newValue, oldValue) {
            var type = scope.vehicle.vehicleType;
            if (type && VehicleData.make[type.id]) {
                var makes = VehicleData.make[type.id];
                scope.vehicleMakeOptions = makeOptions(makes);

                if (idChanged(newValue, oldValue)) {
                    scope.vehicle.vehicleMake = deselected;
                    scope.vehicle.vehicleModel = deselected;
                }
            }
            else {
                scope.vehicleMakeOptions = [ other ];
                scope.vehicle.vehicleMake = other;
            }
        }

        function getModelOptions() {
            let type = scope.vehicle.vehicleType;
            if (!type) return;

            let models = VehicleData.model[type.id];
            if (!models) return;

            let make = scope.vehicle.vehicleMake;
            if (!make) return;

            return models[make.label];
        }

        function vehicleMakeChanged(newValue, oldValue) {
            var modelOptions = getModelOptions();
            if (modelOptions) {
                scope.vehicleModelOptions = makeOptions(modelOptions);

                if (idChanged(newValue, oldValue)) {
                    scope.vehicle.vehicleModel = deselected;
                }
            }
            else {
                scope.vehicleModelOptions = [ other ];
                scope.vehicle.vehicleModel = other;
            }
        }

        function doSave() {
            SaveModal.save().then(saved => scope.vehicle.isOpen = false);
        }

        scope.$watch('vehicle.vehicleType', vehicleTypeChanged);
        scope.$watch('vehicle.vehicleMake', vehicleMakeChanged);
    }

    return directive;
}
}());
