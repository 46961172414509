;(function() {
"use strict";

angular.module('tmr').directive('tripInsertionPoint', [
function () {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: 'directive/trip-insertion-point.html',
        link: link,
        scope: {
            index: '@',
            selectedIndex: '=',
        },
    };

    function link(scope, element, attrs) {
        scope.isSelected = function() {
            // Beware: scope.index is a string, but scope.selectedIndex is a
            // number. Don't use ===
            return scope.index == scope.selectedIndex;
        };
    }

    return directive;
}]);
}());
