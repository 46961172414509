;(function() {
"use strict";

angular
    .module('tmr')
    .controller('TravelController', TravelController);

/* @ngInject */
function TravelController($scope, $stateParams, $timeout, Options, User, Validator, Navigation, Animation, ConfirmationModal, InvalidDataModal, Tenant) {
    let personId   = $stateParams.personId;
    let admin      = User.getData('adminPage');
    let constant  = User.getData('const');

    let person = _.findWhere(admin.people, { id: personId });
    if (_.isUndefined(person) || person.underFive) {
        Navigation.redirectToHome();
        return;
    }

    $scope.$on('back-to-admin-app', (event) => {
        beforeNav();

        let hasInvalidData = validator.hasErrors;

        InvalidDataModal.show(hasInvalidData).then(ok => {
            Navigation.backToAdminApp(User.isLoggedIn());
        });
    });

    _.defaults(person, {
        travel: { },
    });

    // These require the travelPage to have been setup
    let showValidity = person.travel.showValidity;
    let validator    = new Validator($scope, 'travelForm', showValidity);

    // This is a fix for #424. The travel.activity controller got changed from
    // a tmr-radio to a tmr-checkbox group, with a separate checkbox for "did
    // not leave". The "did not leave" checkbox sets activity.noTrips, but
    // tmr-checkbox sets activity to be an array, and these clobber one
    // another.
    //
    // This fix is to:
    // 1. give the did not travel checkbox it's own variable: travel.didNotLeave
    // 2. remap any existing activity values to match the new format.
    //
    // There are four cases:
    // # 1. old form did leave:
    //     travel.activity = {
    //       "text": "Walking e.g. dog walk or walk to the shops",
    //       "id": "walking"
    //     },
    //
    // Fix: move object inside an array, clear flag
    //     travel.activity = [ travel.activity ]
    //     travel.didNotLeave = false
    //
    // # 2. old form did not leave
    //     travel.activity = {
    //       "text": "Did not leave this place for any travel activities",
    //       "id": "nothing",
    //       "noTrips": true
    //     },
    //
    // Fix: clear array, set flag
    //     travel.activity = [ ]
    //     travel.didNotLeave = true
    //
    // # 3. new form did not leave:
    //     travel.activity = {
    //       "noTrips": true
    //     },
    //
    // Fix: clear array, set flag (same fix as #2)
    //     travel.activity = [ ]
    //     travel.didNotLeave = true
    //
    // # 4. new form did leave, may be multiple, but not noTrips
    //     travel.activity = [
    //       {
    //         "text": "Walking e.g. dog walk or walk to the shops",
    //         "id": "walking"
    //       }
    //     ],
    //
    // Fix: keep array, clear flag
    //     travel.didNotLeave = false
    //

    if (_.has(person.travel, 'activity')) {
        let travel = person.travel;

        if (_.isArray(travel.activity)) {
            //console.log("Applying case 4 fix for #424 - doing nothing");
            // Case 4 above.
            // Don't touch travel.didNotLeave - angular will give it a
            // sensible default.
        }
        else if (travel.activity.noTrips) {
            //console.log("Applying case 2/3 fix for #424", angular.copy(travel.activity));
            // Case 2 and 3 above
            travel.activity = [ ];
            travel.didNotLeave = true;
        }
        else {
            // Case 1 above
            //console.log("Applying case 1 fix for #424", angular.copy(travel.activity));
            travel.activity = [ travel.activity ];
            travel.didNotLeave = false;
        }
        //console.log("After fix:", { activity: angular.copy(travel.activity), didNotLeave: travel.didNotLeave });
    }

    // Export data items into the scope
    _.extend($scope, {
        name:       person.name,
        travelDate: constant.date,
        travel:     person.travel,
    });


    function noTrips() {
        return _.getPath(person, 'travel.startingPlace.noTrips')   // out of QLD
           || (hasAddress(person.travel.startingPlace)
               && person.travel.leftHome
               && !person.travel.leftHome.value
               && _.getPath(person.travel, 'didNotLeave'));
    }

    // Wrap status update around navigation functions
    function beforeNav() {
        person.travel.showValidity     = true;
        person.travel.completionStatus = validator.status;
        person.travel.noTrips          = noTrips();
    }

    function canDoNext() {
        return validator.isComplete;
    }

    function nextPage() {
        if (!validator.isComplete) {
            validator.showValidity = true;

            $timeout(250).then(() => {
                let firstError = _.first(document.getElementsByClassName('question-status tmr-incomplete'));
                Animation.scrollToId(firstError.id).then(() => firstError.focus());
            });

            return;
        }

        beforeNav();
        Navigation.next();
    }

    function hasAddress(startingPlace) {
        if (startingPlace){
            if (startingPlace.isHome || startingPlace.isWork || startingPlace.isOther) {
                return true;
            }
        }
        return false;
    }

    // Export the API functions to the scope
    _.extend($scope, {
        nextPage:       nextPage,
        prevPage:       Navigation.wrapPrev(beforeNav),
        scrollToAnchor: Animation.scrollToAnchor,
        hasAddress:     hasAddress,
        canDoNext:      canDoNext,
        tenant:         Tenant,
    });

    var opt = Options.makeOption;

    $scope.activityOptions = [
        opt('walking', 'Walking e.g. dog walk or walk to the shops'),
        opt('cycling', 'Cycling e.g. recreational ride'),
        opt('drive',   'Short drive e.g. to the shops'),
    ];

    $scope.startingPlaceOptions = [
        opt('home',       'At home (the survey household address)',
            { isHome: true }),
        opt('work',       'At work (enter address)',
            {
              needAddress: true ,
              isWork: true ,
            }),
        opt('other',      'At another location (enter address)',
            {
              needAddress: true ,
              isOther: true ,
            }),
        opt('travelling', 'Between locations'),
        opt('outsideqld',
                ( Tenant.isNSW
                    ? 'Outside of Queensland or New South Wales and not returning on my travel day'
                    : 'Outside of Queensland and not returning to Queensland on my Travel Day'
                ),
             { noTrips: true }),
    ];

    $scope.yesNoOptions = Options.yesNoOptions;

}
}());
