;(function() {
"use strict";

angular
    .module('tmr')
    .factory('BooleanAttribute', BooleanAttribute);

/* @ngInject */
function BooleanAttribute() {
    // This service is just a single function.
    // It converts an attribute value to a boolean.
    // Use it in directives:
    // eg. scope: { good: '@' },
    //     link(scope, element, attr) {
    //        scope.good = booleanAttr(attr.good);
    //    }
    //    <directive good>         good = true
    //    <directive good="true">  good = true
    //    <directive good="false"> good = false
    return function(attributeValue) {
        switch(attributeValue) {
            case "":      return true;
            case "false": return false;
        }
        return !!attributeValue;
    }
}
}());
