;(function() {
"use strict";

angular.module('tmr').factory('Timestamp', function() {

    // Timestamp is represented as the number of minutes since the start
    // hour. Timestamps can be compared as integers.

    // Time goes from 4:00am on the day until 3:59pm the next day.
    var startHour = 4;

    // Absolute hour from 0 - 24.
    function makeTimestamp(hour = startHour, minute = 0) {
        hour = hour - startHour;
        if (hour < 0) {
            hour = hour + 24;
        }
        hour = hour % 24;
        minute = minute % 60;

        return hour * 60 + minute;
    }

    // Get the hour to display: 1 - 12
    function getHour(ts) {
        var hour = getHourRaw(ts);
        hour = ((hour - 1) % 12) + 1; // wrap to 1-12
        return hour;
    }

    // Get the raw hour 0 - 23
    function getHourRaw(ts) {
        return (Math.floor(ts / 60) + startHour) % 24;
    }

    function getMinute(ts) {
        return ts % 60;
    }

    function getAmPm(ts) {
        var hour = (Math.floor(ts / 60) + startHour) % 24;
        var ampm = hour < 12 ? "AM" : "PM";
        return ampm;
    }

    function toString(ts) {
        var hour = getHour(ts).toString();
        var minute = getMinute(ts).toString();
        if (minute.length == 1) {
            minute = "0" + minute;
        }
        var ampm = getAmPm(ts);
        return hour + ":" + minute + ampm;
    }

    return {
        make:       makeTimestamp,
        toString:   toString,
        hour:       getHour,
        minute:     getMinute,
        ampm :      getAmPm,
        hourRaw:    getHourRaw,
    };
});
}());
