;(function() {
"use strict";

/* Single radio button directive, used internally by <tmr-radio>
 * Use <tmr-radio> where you can, only use this is you need to insert other
 * controls between the radio buttons.
 */
/* @ngInject */
angular.module('tmr').directive('tmrRadioSingle', function(BooleanAttribute) {

    var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: "directive/tmr-radio-single.html",
        link: link,
        scope: {
            // Required fields
            model:      '=ngModel',
            options:    '=',
            index:      '=',
            name:       '@',
            change:     '&',
            optional:   '@',
        },
    };

    function link(scope, element, attr) {
        scope.option = scope.options[scope.index];
        scope.isOptional = BooleanAttribute(attr.optional);

        // The radio buttons seem to use model.$$hashKey as the key to map
        // between objects and the ng-model. Unfortunately, this doesn't
        // round-trip through the json, so we need to do our own lookup on .id
        function findMatch(id) {
            return _.findWhere(scope.options, { id: id });
        }

        // This used to be in tmr-radio, but had to get pushed down here.
        // We only want one, so we just do it for the first.
        if (scope.index == 0) {
            scope.$watch('model', (newValue, oldValue) => {
                // If the value has no $$hashKey, look one up that does.
                //
                // It gets worse. If we use the browsers back button, we have
                // a $$hashKey, but it is no longer valid. To cover both cases,
                // always look up by .id, and update if the matched object is
                // different to the value.
                if (newValue) {
                    var match = findMatch(newValue.id);
                    if (! _.isEqual(match, newValue)) {
                        scope.model = newValue = match;
                    }
                }
            });

            scope.$watch('options', (newValue, oldValue) => {
                // Make sure that if we change the options and the existing
                // selection is not valid that we clear it.
                if (scope.model && !findMatch(scope.model.id)) {
                    // If the options change and the existing selection is not
                    // valid, clear it.
                    scope.model = undefined;
                }
            });
        }
    }

    return directive;
});
}());
