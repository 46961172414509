;(function() {
"use strict";

angular.module('tmr').directive('stopSelector', ['RouteData',
function (routeData) {

    const directive = {
        restrict: 'E',
        replace: true,
        templateUrl: "directive/stop-selector.html",
        scope: {
            location:       '=',
            endpoint:       '=',
            direction:      '@',
            routeChanged:   '&',
            stopChanged:    '&',
            mandatory:      '@',
        },
        link: link,
    };

    function link(scope, element, attr) {
        let endpoint  = scope.endpoint;
        let direction = scope.direction;
        let mode      = endpoint.mode;
        scope.mode = mode;


        // Only show the route if we have route data for this mode.
        // ie. only buses
        // Otherwise, just show all stops
        let modeData = routeData[mode] || { };
        scope.showRoutes = ! _.isUndefined(modeData.routes);

        if (!scope.location) {
            scope.location = { };
        }

        scope.prompt = {
            route: endpoint.prompt.route,
            stop:  endpoint.prompt[direction],
        };

        scope.placeholder = endpoint.placeholder;
        scope.tooltip = endpoint.tooltip;

        if (scope.showRoutes) {
            scope.routes = modeData.routes;
            scope.$watch('location.route', function() {
                const route = scope.location && scope.location.route;
                scope.stops = modeData.stops[route] || [];

                // grrr am-multiselect doesn't do this automatically
                if (scope.stops.indexOf(scope.location.stop) < 0) {
                    scope.location.stop = undefined;
                }
            });

            let savedRoute = undefined;
            const notFound = 'Route not found';
            scope.$watch('location.otherRoute', function(newValue, oldValue) {
                if (newValue) {
                    const route = scope.location && scope.location.route;
                    if (route) {
                        savedRoute = route;
                    }
                    scope.location.route = notFound;
                }
                else if (scope.location.route == notFound) {
                    scope.location.route = savedRoute;
                }
            });
        }
        else {
            scope.stops = modeData.stops;
        }
    }

    return directive;
}]);
}());
