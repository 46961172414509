;(function() {
"use strict";

angular
    .module('tmr')
    .factory('User', User);

/* @ngInject */
function User($q, Backend, Rollbar, SchemaVersion) {
    let service = {};
    let noUser  = null;
    let user    = noUser;
    let subscribers = [ ];


    // This needs to get updated to match the one in App.pm
    const surveyVersion = 1;

    // Export the API functions to the service object.
    _.extend(service, {
        getData:        getData,
        hasSession:     hasSession,
        isLoggedIn:     isLoggedIn,
        login:          login,
        adminLogin:     adminLogin,
        logout:         logout,
        load:           load,
        save:           save,
        isAdmin:        isAdmin,
        isDeleted:      isDeleted,
        canEnterSurvey: canEnterSurvey,
        status:         Backend.status,
        hasPermission:  Backend.hasPermission,
        deleteSurvey:   deleteSurvey,
        projectInfo:    projectInfo,
        subscribe:      subscribe,
    });

    return service;

    function load() {
        return Backend.load().then(user => {
            setUser(user);
            return true;
        });
    }

    function isAdmin() {
        return Backend.isAdmin();
    }

    function isLoggedIn() {
        return user !== noUser;
    }

    function hasSession() {
        return Backend.hasAuth();
    }

    function login(token, postcode, travelDate) {
        return Backend.login(token, postcode, travelDate).then(user => {
            setUser(user);
            return true;
        });
    }

    function adminLogin(jwt) {
        return Backend.adminLogin(jwt).then(user => {
            setUser(user);
            return true;
        });
    }

    function getData(key, defaultValue = { }) {
        if (!_.has(user, key)) {
            user[key] = defaultValue;
        }
        return user[key];
    }

    function setUser(newUser) {
        if (!isDeleted(newUser)) {
            SchemaVersion.upgrade(newUser);
        }
        let oldUser = user;
        user = newUser;
        notify(oldUser, newUser);
        setRollbar();
    }

    function projectInfo() {
        return _.getPath(user, 'const.project', { });
    }

    // Update Rollbar so it's much easier to track someone's error
    function setRollbar(){
        // Take a copy of the user data because we may mess about with it here.
        let userConst = angular.copy(getData('const'));
        let person = userConst.person;

        // Decorate with user info
        if( isAdmin() && person ) {
            person.username += ' (A)';                // Indicate this was an admin making the call
            person.id = person.id.replace("S", 'A');  // Change the ID from S123 to A123 for admins
            person.admin = isAdmin();                 // Record the admin's ID in the payload
        }

        Rollbar.configure({
            payload: {
                person:     person,
                travelDate: userConst.date
            }
        });
    }

    function logout() {
        Backend.logout();
        let oldUser = user;
        user = noUser;
        notify(oldUser, noUser);
    }

    function setTimestamps(isSubmit) {
        let now        = (new Date()).toJSON();
        let timestamps = getData('timestampsUTC');

        timestamps.saved = now;

        // Only store the timestamp of the first submit. Otherwise the 14 day
        // grace period after submission could be extended indefinitely.
        if (isSubmit && !timestamps.submitted) {
            timestamps.submitted = now;
        }
    }

    function canEnterSurvey() {
        if (isAdmin()) {
            return true; // admin users can always enter a survey
        }

        let submitted = getData('timestampsUTC').submitted;
        if (!submitted) {
            return true; // haven't submitted yet
        }

        let daysAllowed = 14; // how long the survey stays open after submit
        let msSinceSubmit = new Date() - new Date(submitted);
        let daysSinceSubmit = msSinceSubmit / (24 * 60 * 60 * 1000);

        return daysSinceSubmit <= daysAllowed;
    }

    function isDeleted(u = user) {
        return u.deleted && (u.status !== 'deleted-mistake');
    }

    // In order that we don't have multiple save requests in-flight, we store
    // the in-flight promise here, and remove it as soon as the promise is
    // resolved.
    let savePromise = undefined;

    function save({ isSubmit, fromState, toState, personId }) {
        if (!isLoggedIn) {
            return $q.reject("Not logged in");
        }

        if (isDeleted()) {
            // If the survey is deleted, don't try saving it again.
            return $q.when('ok');
        }

        // First check that we don't already have a save request in-flight.
        if (!savePromise) {

          // No saves active, create a new one.
          setTimestamps(isSubmit);
          savePromise = Backend.save(user, fromState, toState, personId)
              .then(
                  success => {
                      // The backend will have updated the sequence id.
                      // Reflect the update here.
                      let seq_key = 'tmrSequence';
                      user[seq_key] = success.data[seq_key];
                  },
                  failure => {
                      if (failure.status == 403) {
                          // session has expired
                          logout();
                      }

                      // re-throw the error
                      return $q.reject(failure);
                  }
              )
              .finally(() => {
                  // Our save promise is complete. Get rid of it.
                  savePromise = undefined
              });
        }
        return savePromise;
    }

    let deletePromise = undefined;
    function deleteSurvey(reason) {
        if (!isLoggedIn) {
            return $q.reject("Not logged in");
        }

        if (!deletePromise) {
            setTimestamps(false);
            deletePromise = Backend.deleteSurvey(reason, user).finally(() => {
                deletePromise = undefined;
            });
        }
        return deletePromise;
    }

    // Subscribe to notifications when the user changes.
    // Returns an unsubscribe function.
    // callback is function(oldUser, newUser)
    function subscribe(callback) {
        function unsubscribe() {
            subscribers = _.reject(callback);
        }
        subscribers.push(callback);
        return unsubscribe;
    }

    function notify(oldUser, newUser) {
        _.each(subscribers, callback => {
            callback(oldUser, newUser);
        });
    }

}
}());
