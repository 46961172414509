;(function() {
"use strict";

angular.module('tmr').factory('Options', function() {

    function makeOption(id, text, extras = { }) {
        var option = _.extend(extras, { id: id, text: text });
        if (id === 'other') {
            option.hasOther = true;
        }
        return option;
    }

    function makeSelect(options) {
        var selections = _.map(options, opt => {
            if (_.isObject(opt)) {
                // Convert a radio/checkbox option to a select option.
                opt.label = opt.text;
                delete opt.text;
            }
            else {
                // Convert a plain string to a select option.
                opt = { label: opt, id: opt };
            }
            return opt;
        });
        selections.unshift({ label: 'Select One', id: '', isDefault: true });
        return selections;
    }

    var opt = makeOption;

    var yesNoOptions = [
        opt('yes',          'Yes',      { value: true }),
        opt('no',           'No',       { value: false }),
    ];

    // Careful, list.reverse() is a mutator - don't reverse yesNoOptions.
    var noYesOptions = _.clone(yesNoOptions).reverse();

    function makeYears(from, to = new Date().getFullYear()) {
        return makeSelect(_.range(from, to+1).reverse());

    }

    function makeNumbers(from, to, last_option) {
        let list = _.range(from, to + 1);

        if (last_option) {
            list.push(last_option);
        }
        
        return makeSelect(list);
    }

    return {
        makeOption:     makeOption,
        makeSelect:     makeSelect,
        makeYears:      makeYears,
        makeNumbers:    makeNumbers,

        noYesOptions:   noYesOptions,
        yesNoOptions:   yesNoOptions,
    };
});
}());
